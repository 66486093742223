import { CssBaseline } from "@mui/material"
import { Routes,Route } from "react-router-dom"
import Dashboard from "./Dashboard/Dashboard"
import './Admin.css'

export const Admin =()=>{
    return(
        <div className="admin-dashboard-container">
        <CssBaseline/>
    <Routes>
        <Route path="/" element={<Dashboard/>} />
    </Routes>
        </div>
    )
}

import './App.css'
import {Routes,Route} from 'react-router-dom'
import Header from './Components/Parents/Header/Header';
import Footer from './Components/Parents/Footer/Footer';
import Home from './Components/Homepage/Home';
import { DashBoard } from './Components/Dashboard/DashBoard';
import MarketPlace from './Components/MarketPlace/MarketPlace';
import Developer from './Components/Developer/Developer';
import Info from './Components/Info/Info'
import SignIn from './Components/Authenticate/SignIn'
import SignUp from './Components/Authenticate/SignUp'
import {Settings} from './Components/User/Settings'
import Follow from './Components/User/followers/Follow';
import Following from './Components/User/followers/Followings';
import PrivacyPolicy from './Components/PrivacyPolicy/Privacypolicy'
import TermsCond from './Components/PrivacyPolicy/TermsCond'
import Feedback from './Components/Info/feedback/Feedback';
import {Admin} from './Components/Admin/Admin';


function App() {
  return (
    <div className="App">
      <Header/>
      <div>
        <Routes>
          <Route path='/' element={<Home/>}/> 
          <Route path='/Dashboard/*' element={<DashBoard/>}/>
          <Route path='/MarketPlace/*' element={<MarketPlace/>}/>
          <Route path='/Developer/*' element={<Developer/>}/>
          <Route path='/Info/*' element={<Info/>}/>
          <Route path='/Admin/*' element={<Admin/>}/>
          <Route path='/signIn' element={<SignIn/>}/>
          <Route path='/signUp' element={<SignUp/>}/>
          <Route path='user/settings' element={<Settings/>}/>
          <Route path='/followers/Follow' element={<Follow/>}/>
          <Route path='/followers/Followings' element={<Following/>}/>
          <Route path='/PrivacyPolicy/Privacypolicy' element={<PrivacyPolicy/>}/>
          <Route path='/PrivacyPolicy/TermsCond' element={<TermsCond/>}/>
          <Route path='/Info/feedback/Feedback' element={<Feedback/>}/>
    
        </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;

import React from 'react';
export default function Maps(){

    return(
      <> 
  <iframe className ="map" width="564" height="400" id="gmap_canvas" title ="iframe" src="https://maps.google.com/maps?q=360%20Spear%20St%20Floor%204,%20Nkoulouloun,%20DLA%2094105&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </>
    )
  }
  
  
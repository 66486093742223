import React from 'react'
import { Routes,Route } from 'react-router-dom'
import DeveloperApiInteg from './DeveloperApiInteg'
import DeveloperPay from './DeveloperPay'
import DeveloperApiDocum from './DeveloperApiDocum'


function Developer() {
  return (
    <Routes>
      <Route path='/' element={<DeveloperApiInteg/>}/>
      <Route path='/DeveloperPay' element={<DeveloperPay/>}/>
      <Route path='/DeveloperApiDocum' element={<DeveloperApiDocum/>} />
    </Routes>
  )
}

export default Developer

import { Grid } from '@mui/material'
import {BiChevronDown} from 'react-icons/bi'
import {AiOutlineArrowUp} from 'react-icons/ai'
import {CgCheckR,CgInfinity} from 'react-icons/cg'
import {VscDebugRestart} from 'react-icons/vsc'
import {MdOutlineCancel} from 'react-icons/md'
import {TbActivityHeartbeat} from 'react-icons/tb'
import {BsUpload} from 'react-icons/bs'
import './TotalUser.css'

const escrowTypes = [
    {name:"Completed Escrows",amount:7800,bc:'#43DC80',ic:'#5A48FB',c:'#64748B',ib:'#43DC801A',dbc:'#ffffff',icon:<CgCheckR size={16}/>},
    {name:"Total Used Funds",amount:1200,bc:'#5A48FB',ic:'#5A48FB',c:'#64748B',ib:'#43DC801A',dbc:'#ffffff',icon:<CgInfinity size={16}/>},
    {name:"Pending Escrows",amount:214,bc:'#FFB930',ic:'#5A48FB',c:'#64748B',ib:'#43DC801A',dbc:'#ffffff',icon:<VscDebugRestart size={16}/>},
    {name:"Rejected Escrows",amount:930,bc:'#FF3030',ic:'#5A48FB',c:'#64748B',ib:'#43DC801A',dbc:'#ffffff',icon:<MdOutlineCancel size={16}/>},
    {name:"Hold Escrows",amount:1200,bc:'#4EE9FE',ic:'#5A48FB',c:'#64748B',ib:'#43DC801A',dbc:'#ffffff',icon:<CgInfinity size={16}/>},
    {name:"Released Escrows",amount:120,bc:'#ACA3FC80',ic:'#5A48FB',c:'#64748B',ib:'#43DC801A',dbc:'#ffffff',icon:<TbActivityHeartbeat size={16}/>},
    {name:"Today Deposit",amount:1250,bc:'#BCFFCB61',ic:'#5A48FB',c:'#FFFFFF',ib:'#FAFAFF',dbc:'#5a48fb',icon:<BsUpload size={16}/>},
    {name:"Total Deposit",amount:10480,bc:'#BCFFCB61',ic:'#04063D',c:'#FFFFFF',ib:'#FAFAFF',dbc:'#04063d',icon:<BsUpload size={16}/>},
]

const Users = ()=>{
    return(
    <div className='admin-total-users-container'>
        <div className="head">
            <p>Total Users</p>
            <div>
                <p>Active Users</p>
                <BiChevronDown/>
            </div>
        </div>
        <div className='below-head'>
            <div className='trend-details'>
                <h2>1,509 Users</h2>
                <p><span><AiOutlineArrowUp/>37.8%</span>vs sept 8,2021</p>
            </div>
        </div>
    </div>
        )
}


const Escrows = ()=>{
    return(
    <div>
        <Grid container spacing={0}>
            <Grid item>

            </Grid>
        </Grid>
        <div className='multiple-admin-escrow-container'>
            
            {escrowTypes.map((escrow,index)=>{
                return(
                    <div className='single-escrow-type' style={{backgroundColor:`${escrow.dbc}`,color:`${escrow.c}`,borderTop:`3px solid ${escrow.bc}`}} key={index}>
                        <div className='single-escrow-left'>
                            <h2>{escrow.amount}$</h2>
                            <p>{escrow.name}</p>
                        </div>
                        <div className='single-escrow-right'>
                            <div style={{backgroundColor:`${escrow.ib}`,color:`${escrow.ic}`}}>
                                {escrow.icon}
                            </div>
                        </div>
                        
                    </div>
                )
            })}
        </div>
    </div>
        )
}



const TotalUsers=()=>{
    return(
    <div className='total-users-container'>
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Users/>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Escrows/>
            </Grid>
        </Grid>
        
    </div>
        )
}

export default TotalUsers

import React from 'react';
import { useForm } from "react-hook-form";

export const Security =()=>{
    const {
        register,
        formState: { errors },
        handleSubmit,
      } = useForm({
        mode: "onBlur",
      });
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
      } = useForm({
        mode: "onBlur",
      });
      const onSubmitId2 = (data) => {
        console.log(JSON.stringify(data.tel));
        alert(`thank you for your message`);
      };
      const {
        register: register3,
        formState: { errors: errors3 },
        handleSubmit: handleSubmit3,
      } = useForm({
        mode: "onBlur",
      });
    
    
    
      const onSubmitId = (data) => {
        console.log(JSON.stringify(data.tel));
        alert(`thank you for your message`);
      };
    return(

        <div>
             <h3>Security</h3>
              <form key={2} onSubmit={handleSubmit2(onSubmitId)} className="privacy">
                <label>New password</label>
                <input type="password" {...register2("newPassword", { required: true })} placeholder="Name" />
                {errors2.newPassword && <span className='error'>* Enter your New Password</span>}
                <label>Confirm</label>
                <input type="password" {...register2("confirmPassword", { required: true })} placeholder="Name" />
                {errors2.confirmPassword && <span className='error'>* Confirm your New Password</span>}
                <input type="submit" className='inactive' />
              </form>

              <form key={3} onSubmit={handleSubmit3(onSubmitId2)} className="authentic-form privacy">
                <label>Two Factors Authentification</label>
                <input type="email" placeholder="Enter personal email address" {...register3("email", { required: true })} />
                {errors3.email && <span className='error'>This field is required</span>}
                <input type="tel" placeholder="Enter your phone number " {...register3("tel", { required: true })} />
                {errors3.tel && <span className='error'>This field is required</span>}
                <input type="email" placeholder="Bricefongang@gmail.com" {...register3("email2", { required: true })} />
                {errors3.email2 && <span className='error'>This field is required</span>}
                <input type="text" placeholder="Add biometric ID" {...register3("biometricID", { required: true })} />
                {errors3.biometricID && <span className='error'>This field is required</span>}
                <input type="submit" />
              </form>


              <div className='security-box privContent' >
                <h2>Extra Security</h2>
                <form onSubmit={handleSubmit()}>
                  <div className='check-cont'>
                    <label class="check">
                      <input type="checkbox" placeholder="Get alerts about unrecognized logins " {...register("Get alerts about unrecognized logins ", {})} />
                      <span class="checkmark"></span>
                    </label>
                    <p >Get alerts about unrecognized logins <span>We’ll let you know if anyone logs in from a device or browser you don’t usually use. </span></p>
                  </div>
                  <div className='check-cont'>
                    <label class="check">
                      <input type="checkbox" placeholder="Safe Browsing" {...register("Safe Browsing", {})} />
                      <span class="checkmark"></span>
                    </label>
                    <p>Safe Browsing <span>Display a warning if you attempt to navigate to a potentially dangerous or deceptive website through our platform </span></p>
                  </div>
                </form>
              </div>

              <div className='privacy-box privContent'>
                <h2>Privacy</h2>
                <h4>Data Controls </h4>
                <p>We’ll let you know if anyone logs in from a device or browser you don’t usually use. </p>
                <h4 >Data Controls </h4>
                <p >We’ll let you know if anyone logs in from a device or browser you don’t usually use. </p>
                <h4 >Data Controls </h4>
                <p>We’ll let you know if anyone logs in from a device or browser you don’t usually use. </p>
              </div>

              <div className='help-box privContent'>
                <h2>Get help</h2>
                <h4>Learn more about security </h4>
                <div className='faq-links'>
                  <a href="/"> If you think your account was hacked </a>
                  <a href="/">If you’ve noticed unusual activity on your account, tell us what's happening so we can help you. </a>
                </div>
              </div>
              </div>
 
    )
}

import {Grid,InputLabel,MenuItem,FormControl,Select,Button,TextField,Box} from '@mui/material'
import { useMediaQuery } from 'react-responsive';
import bitcoin from '../../../assets/bitcoin-yellow.svg'
import React from 'react' 
import { useForm } from 'react-hook-form';
import { Modal, Fade, Typography, Backdrop } from '@mui/material';
import './FirstView.css';
import closecircle from '../../../assets/closecircle.svg';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const FirstView =()=>{
  const {
    register: register6,
    formState: { errors: errors6 },
    handleSubmit: handleSubmit6,
    reset: reset,
  } = useForm({
    mode: "onBlur",
  });
  const isMobile = useMediaQuery({query:`(max-width:600px)`})
  const onSubmit =()=>{
    alert('form submitted')
  }

  const {
    register: register22,
    formState: { errors: errors22 },
    handleSubmit: handleSubmit22,
  } = useForm({
    mode: "onBlur",
  });

  const contractForm1 = (data) => {
    console.log(JSON.stringify(data.tel));
    alert(`Login Successful!`);
    reset();
  };

const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

const [selectedOption,changeSelected] = React.useState("I'm Buying")
const [selectedCurrency,changeCurrency] = React.useState("USD")


//Becasue different actions by the user would generate different form inputs, actions has been grouped into objects as you can see below... any futher action to beadded should use the same format!!
const user_select_options=[
{name:"I'm Buying",
inputs:[
  {name:"Item-name",placeholder:"Name of the Item",data:"Item name",type:"text"},
  {name:"Item-Location",placeholder:"Location of the Item",data:"Item Location",type:"text"},
  {name:"Item-Condition",placeholder:"Condition of the Item",data:"Item Condition",type:"text"},
],
invitee_details:[
  {name:"Email",placeholder:"Email of Invitee",data:"invite email",type:"email"},
  {name:"Tel",placeholder:"Telephone of Invitee",data:"Invite Tel",type:"tel"}
]
  },

{name:"I'm Selling",
inputs:[
  {name:"Item-name",placeholder:"Name of the Item",data:"Item-S-name",type:"text"},
  {name:"Item-Location",placeholder:"Location of the Item",data:"Item-S-Location",type:"text"},
  {name:"Item-Condition",placeholder:"Condition of the Item",data:"Item-S-Condition",type:"text"}
],
invitee_details:[
  {name:"Email",placeholder:"Email of Invitee",data:"invite-email",type:"email"},
  {name:"Tel",placeholder:"Telephone of Invitee",data:"Invite Tel",type:"tel"}
]
  },
]

///////Populate user options data to array
const user_options = [];
user_select_options.forEach(option=>{
  if(user_options.includes(option)){
    //DO Nothing 
  }
  else(
    user_options.push(option.name)
  )
})
/////////////////

const selectedWithData = user_select_options.find(option=>option.name===selectedOption)
// console.log(selectedWithData)

return(
  <div className="page-wrapper">
<div className='firstView'>
  <Grid container spacing={0}>

    {/*Text on Left Grid*/}

    <Grid item sm={12} md={6} lg={6} xl={6}  >
      <div className='blurry-background blur-5'></div>
      <div className='blurry-background blur-6'></div>
      <div>
      <div className='Bigh1-72 gradient-text'> <span className='Bigh1-72 never'>Never</span> <span>buy or sell without using OnlineP2P</span></div>
      </div>
    </Grid>

    {/*Form on Right Grid*/}

    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
      <form onSubmit={handleSubmit6(onSubmit)}>
        {/* <div>   
          <img src={bitcoin} alt='bitcoinimage' className='coinImgAnimT' css={styles.bitcoinImage} id='coinImg' onAnim ationEnd={console.log('ended')}/>
        </div>          */}
        <div className='form-div' >

          {/*Separates 'Im selling input from domain, nane input'*/}

          <Grid container spacing={isMobile?0:1}> 
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>

              {/*Input: 'Im Selling?*/}

              <FormControl classes={{root:'mui-form-control'}}>
              <InputLabel>Action</InputLabel>
                <Select {...register6("action",{required:true})} 
                label='Action'
                classes={{select:'mui-form-select'}}
                value={selectedOption}
                onChange={(e)=>{changeSelected(e.target.value);}}
                
                >
                  {user_options.map((option,index)=>{
                    return(
                      <MenuItem value={option} key={index}>{option}</MenuItem>
                    )
                  })}
                </Select>
                {errors6.action && <span className="error">*Choose your action</span>}
              </FormControl>
            </Grid>
            <Grid item  xs={12} sm={7} md={7}  xl={7}>

              {/*Input: 'Domain, name, etc?'*/}
              <input 
              type='text' 
              className='homeInput'
              placeholder='Domain, Vehicles, etc'
              {...register6("item",{required:true})}
              />
              {errors6.item && <span className="error">*specify an item</span>}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item  xs={12} sm={8} md={8}  xl={8}>
              {/*Input: 'Domain, name, etc?'*/}
              <FormControl  classes={{root:'mui-form-control'}}> 
                <TextField label="For $" 
                variant="outlined" 
                type='number'   
                classes={{root:'form-inputField'}}
                {...register6("amount",{required:true})}
                />
                {errors6.amount && <span className="error">*input amount</span>}
              </FormControl>
            </Grid>
            <Grid item  xs={12} sm={4} md={4}  xl={4}>
            {/*Input: 'Im Selling?*/}
            <FormControl classes={{root:'mui-form-control'}}> 
              <InputLabel>Currency</InputLabel>
                <Select
                label='Currency'  
                classes={{select:'mui-form-select'}}
                {...register6("currency",{required:true})}
                value={selectedCurrency}
                onChange={(e)=>changeCurrency(e.target.value)}
                >
                  <MenuItem value='USD'>USD</MenuItem>
                  <MenuItem value='AUD'>AUD</MenuItem>
                  <MenuItem value='FRS'>FRS</MenuItem>
              </Select>
              {errors6.currency && <span className="error" >*Choose currency</span>}
            </FormControl>
            </Grid>
          </Grid>
            <button type='submit'  onClick={handleOpen} fullwidth="true" > Create a Contract</button>
        </div>
      </form>
    </Grid>
  </Grid>
</div>

    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        className="contractformWrap" >
        <Fade in={open}>
            <Box sx={style}>    
                <h3>{selectedOption}</h3>
                <form key={22} onSubmit={handleSubmit22(contractForm1)} className="contractForm">
                  <Grid container spacing={1}>
                    {selectedWithData.inputs.map((input,index)=>{
                      return(
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                          <label>{input.data}</label>
                          <input name={input.name} type={input.type} placeholder={input.placeholder} {...register22(`${input.name}`, { required: true })}/>
                          {errors22[`${input.name}`] && <span className='error'>Required Field</span>}
                        </Grid>
                      )
                    })}
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <label>Price</label>
                        <input type="text" placeholder='Item Price' {...register22("price", { required: true})}/>
                        {errors22.price && <span className='error'>* Enter the price</span>}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <label>Currency</label>
                        <input type="text" value={selectedCurrency} readOnly/>
                      </Grid>
                    </Grid>
                  </Grid>
                  <h3>Have someone you wish to invite?</h3>
                  {/* <h4>Invitee details</h4> */}
                  <Grid container spacing={1}>
                      {selectedWithData.invitee_details.map((input,index)=>{
                        return(
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                            <label>{input.data}</label>
                            <input type={input.type} placeholder={input.placeholder} name={input.name} {...register22(`${input.name}`, { required: true })}/>
                            {errors22[`${input.name}`] && <span className='error'>Required Field</span>}
                          </Grid>
                        )})
                      }
                  </Grid>
                  <input type="submit" value="View contract" className='mar-cen' />
                </form>
                <span onClick={handleClose} className="closebtn"><img src={closecircle}/></span>
            </Box>
        </Fade>
    </Modal>
</div>
)
}

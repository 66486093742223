import React from 'react';
import developerimg2 from '../../assets/developerimg2.jpg';
import api from '../../assets/api.png';
import b2b from '../../assets/b2b.png';
import developerimg1 from '../../assets/developerimg1.png';
import './developerStyle.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';



function DeveloperApiInteg() {
const navigate=useNavigate()
  return (
    <>
      <div className="apiHero">
        <div className="container">
          <div className="columns-wrap">
            <div className="col col-left">
              <h2> Payments for your website, mobile app or marketplace with no chargebacks, ever.</h2>
              <p>With no minimum fee and priced as low as 0.89%, the Escrow API is cheaper than credit cards and many other online payment methods.</p>
              <div className="buttons-wrap">
              <Button variant='contained' type='submit' onClick={()=>navigate('/signUp')}>Create account</Button>
              <Button variant='contained' className='trans-btn btn' onClick={()=>navigate('/Dashboard/Create-contract')}>Create a Contract</Button>
              {/* <a href="#" className="trans-btn btn" type='submit'>Create a Contract</a> */}
              </div>
            </div>
            <div className="col col-right">
              <img src={b2b} alt="b2b" />
            </div>
          </div>
        </div>
      </div>

      <div className="icons-section">
        <div className="container">
          <div className="icons-columns-wrap">
            <div className="col">
              <div className="icon-img">
                 <i class="fa-solid fa-image"></i>
              </div>
              <h5>Track record</h5>
              <p>Incorporated in 1999 by Fidelity National Financial, over US$5 billion in transactions have been protected. Escrow.com has over 21 years of operating a licensed, regulated, and regularly audited escrow service.</p>
            </div>
            <div className="col">
              <div className="icon-img">
                 <i class="fa-solid fa-image"></i>
              </div>
              <h5>No chargebacks</h5>
              <p>Buyers get to inspect the goods or services before accepting them, protecting the buyer. Likewise sellers are protected from counterparty risk with no chargebacks, ever.</p>
            </div>
            <div className="col">
              <div className="icon-img">
                 <i class="fa-solid fa-image"></i>
              </div>
              <h5>Cost effective</h5>
              <p>With no minimum fee and priced as low as 0.89%, the Escrow API is cheaper than credit cards and many other online payment methods.</p>
            </div>
            <div className="col">
              <div className="icon-img">
                 <i class="fa-solid fa-image"></i>
              </div>
              <h5>Award winning</h5>
              <p>Escrow.com is the winner of the 2017 BBB Torch Award for Ethics for Silicon Valley, San Francisco and the Bay Area, an award presented to a business that goes above and beyond in their business dealings with customers, other businesses and the community.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="cta-section">
        <div className="container">
          <div className="cta-column-wrap">
            <div className="cta-col">
              <h2> Over $5 billion of transactions protected with P2POnline</h2>
              <p>Escrow.com is the world’s most secure payments method from a counterparty risk perspective- safeguarding both buyer and seller, all funds transacted using escrow are kept in trust.</p>
              <div className="buttons-wrap btn-center">
              <Button variant='contained' type='submit' onClick={()=>navigate('/signUp')}>Create account</Button>
              </div>
            </div>
            <div className="cta-img api-int-section">
              <img src={developerimg1} alt="b2b" />
            </div>
          </div>
        </div>
      </div>


      <div className="img-cta-section">
        <div className="container">
          <div className="columns-wrap reverse-column-wrap">
            <div className="col col-left">
              <h2> Integrate P2POnline Pay in only one line of code</h2>
              <p>We've made it even easier for you to integrate P2POnline payments. Add P2POnline Pay to your business with a single API call. Reduce complicated engineering overheads and improve your sales today.</p>
              <div className="buttons-wrap">
              <Button variant='contained' type='submit' onClick={()=>navigate('./DeveloperApiDocum')}>Integrate Now</Button>
              </div>
            </div>
            <div className="col col-right">
              <img src={developerimg2} alt="b2b" />
            </div>
          </div>
        </div>
      </div>

      <div className="api-features-section">
        <div className="container">
          <div className="cta-column-wrap">
            <div className="cta-col">
              <h2>Benefits of using the P2POnline API</h2>
            </div>
            <div className="icons-columns-wrap">
            <div className="col">
              <div className="icon-img">
              <i class="fa-brands fa-rust"></i>
              </div>
              <h5>Increase Trust</h5>
              <p>Allow your customers to transact on e-commerce, marketplaces & classifieds sites with higher trust & safety greatly reducing fraud and with no chargebacks, ever.</p>
            </div>
            <div className="col">
              <div className="icon-img">
              <i class="fa-solid fa-globe"></i>
              </div>
              <h5>Increase Liquidity</h5>
              <p>Physical inspection & acceptance happen on delivery, meaning valuable items can ship across state or country lines before the transaction completes, allowing your customers to expand beyond the local area to a global market safely.</p>
            </div>
            <div className="col">
              <div className="icon-img">
              <i class="fa-regular fa-square-check"></i>
              </div>
              <h5>Increase Fulfillment</h5>
              <p>Greater liquidity means sellers can access more buyers and buyers more sellers, resulting in better choice and pricing and increasing the probability of a successful transaction.</p>
            </div>
            <div className="col">
              <div className="icon-img">
              <i class="fa-solid fa-circle-exclamation"></i>
              </div>
              <h5>Increase Support</h5>
              <p>Your customers benefit from our world class transaction support. Experienced Escrow.com personnel can check shipping documentation, title, liens, and more.</p>
            </div>
            <div className="col">
              <div className="icon-img">
              <i class="fa-regular fa-circle-check"></i>
              </div>
              <h5>Increase Compliance</h5>
              <p>Escrow.com provides “compliance as a service” performing know your customer & anti-money laundering verification.</p>
            </div>
            <div className="col">
              <div className="icon-img">
              <i class="fa-solid fa-shield-halved"></i>
              </div>
              <h5>Increase Insights</h5>
              <p>Escrow.com “closes the loop” providing insight into what happens to your customers after they match on your site.</p>
            </div>
            <div className="col">
              <div className="icon-img">
              <i class="fa-solid fa-arrow-trend-up"></i>
              </div>
              <h5>Increase Conversion</h5>
              <p>Campaigns and ad targeting can run more effectively. Know which listings are up to date and which are expired, creating a more relevant experience for your audience.</p>
            </div>
            <div className="col">
              <div className="icon-img">
              <i class="fa-solid fa-money-check-dollar"></i>
              </div>
              <h5>Increase Revenue</h5>
              <p>Revenue share is available for volume partners of $1 million per month or more.</p>
            </div>

          </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default DeveloperApiInteg

import './AboutUs.css';
import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import neil from '../../../assets/neil.jpg';
import matt from '../../../assets/matt.jpg';
import { Button } from '@mui/material';

export default function About() {

  const navigate = useNavigate()

  return (
    <>
      <div className="about-wrapper">

        <div className="hero-about hero-sect">
          <div className="text-container">
            <h1>About Us</h1>
            <p>We're not just an online OnlineP2P service, We're THE online escrow servics</p>
            <Button variant='contained' type='submit' onClick={()=>navigate('/')}>Get Started Now</Button>
          </div>
        </div>

        <div className="about-content">
          <div className="container">
            <h2>Our History</h2>
            <p>We were the first company to create online escrow over a decade ago. We're leading the way in safe, reliable and trustworthy escrow services and will be for many years to come.</p>
            <h3>Escrow.com is:</h3>
            <ul>
              <li>Online escrow that is simple and safe for Buyers and Sellers</li>
              <li>Compliant with relevant state laws</li>
              <li>Armed with an in-depth knowledge of the escrow process</li>
              <li>The safest service to trust with your money</li>
            </ul>
            <p>Headquartered in San Francisco, California, Escrow.com and its operating subsidiaries provide online escrow services that facilitate and accelerate e-commerce by assuring a secure settlement.</p>

            <p>Escrow.com has pioneered the process of online escrow services. The Company, founded in 1999 by Fidelity National Financial, has established itself as one of the leading providers of secure business and consumer transaction management on the Internet. Escrow.com was purchased by Freelancer.com (ASX:FLN) in 2015.</p>

            <p>All escrow services offered on this website are provided independently and exclusively by Internet Escrow Services (SM) (IES), one of the operating subsidiaries of Escrow.com. IES is fully licensed and accredited as an escrow company and is subject to compliance with applicable escrow regulations, including the California Financial and Business Code.</p>
       <h3>Benefits to the buyer</h3>
<p>When making purchases online, it is hard to guarantee that you’re getting what you pay for. While confusion can often arise based on innocent misunderstandings, there are also occasions when people abuse the anonymity that the Internet provides for the purpose of deliberately misleading others to part with their money. Escrow.com removes all elements of doubt on the behalf of a buyer that their money will be spent exactly as intended.</p>

<p>Money isn’t released to the seller until they are satisfied with the goods and shipping and delivery are tracked by the third party which ensures that no items ‘go missing’ once a buyer has made their payment.</p>

<h3>Benefits to the seller</h3>
<p>The seller is notified by Escrow.com once they have received payment on their behalf which guarantees that as soon as the merchandise has been received payment will be made. This avoids those instances of having to chase customers for unpaid invoices or having to dispute those buyers who ‘didn’t receive’ their goods and are requesting refunds.</p>

<p>With Escrow.com, there is no need to rely on trusting a stranger to complete your online transactions. It provides a safe and secure third party environment which will ensure that your internet buying and selling take place worry-free and with ease.</p>
          </div>
        </div>

        <div className="team-section">
          <div className="container">
            <div className="text-container">
              <h1>Meet our leaders</h1>
              <p>Escrow.com’s management team is comprised of industry-leading professionals</p>
            </div>


            <div className="team-wrap">
              <div className="col-left col">
                <img src={neil} alt="" />
              </div>
              <div className="col-right col">
                <h3>NEIL KATZ</h3>
                <span>Chief Financial Officer</span>
                <p>Matt Barrie is an award winning technology entrepreneur. Matt was Adjunct Associate Professor at the Department of Electrical and Information Engineering at the University of Sydney where he taught Cryptography for fifteen years and, later, Technology Venture Creation. He is the co-author of over 20 US patent applications.</p>
              </div>
            </div>

            <div className="team-wrap reverse">
              <div className="col-left col">
                <img src={matt} alt="" />
              </div>
              <div className="col-right col">
                <h3>MATT BARRIE</h3>
                <span>CEO and President</span>
                <p>Matt Barrie is an award winning technology entrepreneur. Matt was Adjunct Associate Professor at the Department of Electrical and Information Engineering at the University of Sydney where he taught Cryptography for fifteen years and, later, Technology Venture Creation. He is the co-author of over 20 US patent applications.</p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

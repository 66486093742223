import './Listings.css'

const SupportTickets = ()=>{
    return(
    <div>
        Support tickets section
    </div>
     )
}

const TransactionListings=()=>{
    return(
        <div>
            Transaction Listings Here
        </div>
        )
}

 const Listings =()=>{
    return(
        <div>
            <SupportTickets/>
            <TransactionListings/>
        </div>
        )
}

export default Listings

import React from 'react'
import Overview from './Overview/Overview'
// import "../../DashBoardStyles.css"
import { Route,Routes } from 'react-router-dom'
import { CssBaseline, Grid} from '@mui/material'
import { useMediaQuery } from 'react-responsive'
import  {UserData}  from './UserActions/User'
import { SellContract } from './Contract/Sell_contract'
import './Dashboard.css'

export const DashBoard=()=>{
  const isMobile = useMediaQuery({query:`(max-width:600px)`})
  const isSmallScreen = useMediaQuery({query:`(max-width:1200px)`})
  return (
    <div className='page-wrapper dashboard-section'>
      <div className='dashboard-wrapper'>
          <CssBaseline/>
          <Grid container>
          {/* <ScrollButton/> */}
          <Grid item xl={3} lg={3} md={3}> 
            <div className='Dashboard-user-info-wrapper' >
            {!isMobile && !isSmallScreen && <UserData/>} {/*Hide When user is on mobile or small display*/}
            
          </div>
        </Grid>
        <Grid item xl={9} lg={9} md={12} >
        <Routes>
          <Route path='/' element={<Overview/>}/>
          <Route path='/Create-contract' element={<SellContract contract_type='service'/>}/>
        </Routes>
        </Grid>
          </Grid>
    </div>
    </div>
  )
}


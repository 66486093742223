
import React ,{ useCallback , useEffect } from 'react';
import { useForm } from "react-hook-form";
import './style.css';
import '../../App.css'

export default () => {
    const {
        register: register4,
        formState: { errors: errors4 },
        handleSubmit: handleSubmit4, 
        reset:reset,
        isSubmitSuccessful

      } = useForm({
        mode: "onBlur",
      });

      const onLogin = (data) => {
        console.log(JSON.stringify(data.tel));
        alert(`thank you for your message`);
      };


      const resetAsyncForm = useCallback(async () => {
        const result = await fetch('./api/formValues.json'); // result: { firstName: 'test', lastName: 'test2' }
        reset(result); // asynchronously reset your form values
      }, [reset]);
      
      useEffect(() => {
        resetAsyncForm()
      }, [isSubmitSuccessful])

    return(
        <>
          <div className='login-wrap'>
            <div className='container'>
              <h2 className='center-text smallh1-48'>Se connecter à <span>OnlineP2P</span></h2>
               <p className='center-text'>Welcome back! Please enter your details.</p>
               <form key={4} onSubmit={handleSubmit4(onLogin)} className="login">
                <label>Email</label>
                <input type="email" {...register4("email", {  required: true, pattern: /^\S+@\S+$/i })} placeholder="Name" />
                {errors4.email && <span className='error'>* write the email with correct pattern</span>}
                <label>Mot de passe</label>
                <input type="password" {...register4("password", { required: true })} placeholder="Name" />
                {errors4.password && <span className='error'>* Enter your Password</span>}
                <div className='checkwrap'>
                    <div className='check-cont'>
                        <label class="check">
                        <input type="checkbox" {...register4("Safe Browsing", {})} />
                        <span class="checkmark"></span>
                        </label>
                        <p>Se souvenir de moi</p>
                    </div>
                    <a href='/'>Mot de passe oublié?</a>
                </div>
                <input type="submit" value="Se Connecter" />
                <p className='center-text forget-pass'>Don’t have an account?<a href='/'>Sign Up</a></p>
              </form>
          </div>
          </div>
        </>
    )
}

import { Avatar,Rating } from '@mui/material'
import {HiOutlineUser,HiChevronRight} from 'react-icons/hi'
import {TbActivityHeartbeat} from 'react-icons/tb'
import {BsBookmarkDash} from 'react-icons/bs'
import {AiOutlineMenu} from 'react-icons/ai'
import {FiSettings} from 'react-icons/fi'
import './UserStats.css'

const adminData = [
    {icon:<HiOutlineUser className='admin-ico'/>,name:'User Chat'},
    {icon:<TbActivityHeartbeat className='admin-ico'/>,name:'Payout Statistics'},
    {icon:<BsBookmarkDash className='admin-ico'/>,name:'Support'},
    {icon:<AiOutlineMenu className='admin-ico'/>,name:'Transaction Listing'},
    {icon:<FiSettings className='admin-ico'/>,name:'User Config'}
]

const UserStats =()=>{
    return(
        <div className='admin-user-stats'>
            <div className='admin-user-about'>
                <Avatar classes={{root:"userAvatar"}}/>
                <h5>Brice Fongang</h5>
                <p>Financial expert</p>
                <Rating value={2} max={4} readOnly/>
            </div>
            <div className='admin-user-options'>
                {adminData.map((data,index)=>{
                    return(
                        <div>
                            <div className='admin-options-single-left'>
                                <div className='admin-options-single-ico'>
                                    {data.icon}
                                </div>
                                <p>{data.name}</p>
                            </div>
                            <div className='admin-options-single-ico'>
                                <HiChevronRight/>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


export default UserStats

import React from 'react'
import './Location.css'


const TempStats = [
    {location:'Melbourne, AUS',amount:'100,000,000'},
    {location:'Melbourne, AUS',amount:'100,000,000'},
    {location:'Melbourne, AUS',amount:'100,000,000'},
    {location:'Melbourne, AUS',amount:'100,000,000'},
]

export const LocationStats =()=>{
    return(
        <div className='locations-continer'>
            <h2>Location statistics</h2>
            <div>
                {TempStats.map((stat,index)=>{
                    return(
                        <div key={index} className='single-location'>
                            <div className='loc-flag'>
                            </div>
                            <div className='loc-contents'>
                                <p>{stat.location}</p>
                                <p>{stat.amount}</p>
                            </div>
                        </div>
                    )
                })}
        </div>
        </div>
    )
}

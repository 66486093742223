import React from 'react'
import { user } from '../Overview/StaticData';
import { Avatar,Rating,Chip} from '@mui/material';
import NoteIcon from '@mui/icons-material/Note';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import bitcoin from '../../../assets/bitcoin-yellow.svg'
import personIcon from '../../../assets/person.svg'
import peopleIcon from '../../../assets/people.svg'
import bannerIcon from '../../../assets/banner.svg'
import settingicon from '../../../assets/settings.svg'
import chatIcon from '../../../assets/chat.svg'
import { useNavigate } from 'react-router-dom';
import './User.css'

const UserInfo =()=>{

const navigate = useNavigate();
    return(
      <div className='user-info-container1' > {/*Th Div containing the logged in user Information*/}
      <div className='user-info-container1-sub'>
      <Avatar src={bitcoin} classes={{root:"userAvatar"}}/>
              <p className='p-16 userName'>{user.name}</p>
              <p className='p-14 userTitle'>{user.title}</p>
              <Rating value={user.rating} max={4} readOnly/>
      </div>
              <div className='theButtons'>
              <button 
                type='submit'
                onClick={()=>navigate('Create-contract')}> <DashboardCustomizeIcon/>
                Create new Escrow
              </button>
              <button 
              className='Gen-btn'
              type='submit'>
                <NoteIcon/>Generate a report</button>
              </div>
              
            </div>
    )
  }

export const UserActions =()=>{
  const navigate = useNavigate();
    return(
      <div className='user-info-container2'> {/*The div about the followers, following, settings,etc*/}
                <div className='singleUser-info'>
                  <img src={personIcon}/>
                  <p>Followers</p>
                  <Chip label={user.followers}/>
                </div>
                <div className='singleUser-info'>
                <img src={peopleIcon}/>
                  <p>Following</p>
                  <Chip label={user.following}/>
                </div>
                <div className='singleUser-info'>
                <img src={bannerIcon}/>
                  <p>Escrow saved</p>
                  <Chip label={user.escrowSaved}/>
                </div>
                <div className='singleUser-info'>
                <img src={chatIcon}/>
                  <p>Messages</p>
                  <Chip label={user.escrowSaved}/>
                </div>
                <div className='singleUser-info' onClick={()=>navigate('/user/settings')}>
                <img src={settingicon}/>
                  <p>Settings</p>
                  <Chip label={4}/>
                </div>
                <div className='singleUser-info logout'>
                  <LogoutIcon/>
                  <p>Log Out</p>
                </div>
            </div>
    )
  }

  export const UserData =()=>{
    return(
      <div className='user-data-wrapper'>
      <UserInfo/>
      <UserActions/>
    </div>
    )
    
  }

import './TotalSubs.css'

const TotalSubsGraph =()=>{
    return(
    <div>
        Total subs Graph here
    </div>
        )
}

const PayoutStatistics=()=>{
    return(
    <div>
        Payout Statistics here
    </div>
        )
}


const Totalsubs =()=>{
    return(
    <div>
        <TotalSubsGraph/>
        <PayoutStatistics/>
    </div>
        )
}

export default Totalsubs

import React from 'react'
import { FirstView } from './FirstView/FirstView'
import { FithView } from './FithView/FithView'
import { FourthView } from './FourthView/FourthView'
import { SecondView } from './SecondView/SecondView'
import { SixthView } from './SixthView/SixthView'
import { ThirdView } from './ThirdView/ThirdView'
import '../../App.css'
// import ScrollButton from './handleScroll'

{/*When creating mobile view,,, items shrink or increase in size when user focus on an input element...code below fixes that*/}


{/*Each section of the homepage is divided into views....so you have the first, second , third, till sixth view*/}

function Home() {
  return (
  <>
      <div>
        {/* <ScrollButton/>  */}
        <FirstView/>
        <SecondView/> 
        <ThirdView/>
         <FourthView/>
       <FithView/> 
        <SixthView/> 
      </div>
    </>
  );
}

export default Home

import React from 'react'
import millify from 'millify';
import { Grid,CssBaseline } from '@mui/material'
import tropphyBadge from '../../../assets/trophyBadge.svg'
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { LineChart,LineChart2 } from '../Charts/LineChart';
import { BarChart } from '../Charts/BarChart';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { user,ovRow1Data,ovRow2Data,dispu_resolved} from './StaticData';
import TransactionsHistory from '../TransactionHistory/Transaction';
import { useMediaQuery } from 'react-responsive'
import { FeedBack } from '../FeedBack/Feedback';
import { LocationStats } from '../LocationStats/LocationStats';
import './Overview.css'

{/*Lots of data transmission would be going on here....all user data from last week,the current week last month,and the current month are supposed to be compared so the various icons for increase and decrease are diaplayed accordingly...Static data would be used for now since a backend is not yet set up*/}



const OverviewRow1 =()=>{
  return(
    <div className='overview-first-row-contanier' >
      <Grid container spacing={1}>
      {ovRow1Data.map(data=>{  {/*Mapping trough the first row of overview ie Balance,total Deposit,ets*/}
              return(
                <Grid item xl={4} md={4} lg={4} sm={6} xs={12}>
                  <div className='overview-first-row-single-container'>
                  <div>
                    <span className='ico'>{data.icon}</span>
                    <div className='overview-nameContainer'>
                      <p>{data.name}</p>
                      <div>
                      <PriorityHighIcon className='exclamIcon'/>
                      </div>
                    </div>
                      <h1>{millify(data.amount)}</h1>
                        {data.trend}
                  </div>
                  <div className='lineChartDiv'>
                    <LineChart lineColor={data.lineColor} theData ={data.data} theLabels={data.labels}/>
                  </div>
                  </div>
                </Grid>
              )
            })}
      </Grid>
      
    </div>
  )
}


const OverviewRow2 =()=>{
  return(
    <div className='overview-second-row-container'> {/*Overview-SecondRow*/} 
    <Grid container spacing={2}>
            {ovRow2Data.map(data=>{{/*Mapping trough the second row of overview ie completed,pending,etc escrows*/}
              return( 
                <Grid item xl={2} lg={2} md={4} sm={4} xs={6}>
              <div className='overview-second-row-single-container' style={{borderTop:data.bColor}}>
                  <div>
                    <h1>{data.amount}</h1>
                    <p>{data.name}</p>
                  </div>
                  <div>
                    <span style={{color:data.color,backgroundColor:data.bgColor}}>{data.icon}</span>
                  </div>
              </div>
              </Grid>
            )})
            }
          </Grid>
          </div>
  )
}

const TopSales_Tickets=()=>{
  return(
    <div className='sales-dash-sec'>
   <div className='smaller-container'> {/*Total sales1*/}
        <div className='smaller-container-single'>
          <p>Total sales</p>
          <h3>{millify(user.totalSales)}</h3>
        </div>
        <div className='smaller-container-single'>
          <p>Support tickets</p>
          <h3>24</h3>
        </div>
    </div>
    </div>
 
  )
  }



const TopSales_graph=()=>{
  return(
    <div className='graph-container'> 
      <p>Top sales</p>
      <div className='TheBar'>
        <BarChart theLabels={user.topSales.map(item=>item.label)} theData={user.topSales.map(item=>item.amount)} Color={['#37d493','#9efdd6','#9efdd6']} />
      </div>
    </div>
  )
}


const AdsRecordsGraph =()=>{
  return(
    <div className='graph-container'>
    <p>Ads Records</p>
    <div className='trophy'>
      <h1>17</h1>
      <img src={tropphyBadge}/>
    </div>
    <div className='TheBar'>
      <BarChart theLabels={user.topSales.map(item=>item.label)} theData={user.topSales.map(item=>item.amount)} Color={['#ffaa2a','#ffe0b2','#ffe0b2']} />
    </div>
  </div>
  )
}

const TotalContracts = ()=>{
  return(
    <div className='line-container'>
      <h2>Total contracts</h2>
      <div>
        <LineChart2 lineColor='#37d694' theData={user.contracts.map(data=>data.amount)} theLabels={user.contracts.map(data=>data.label)} />    
      </div>
    </div>
  )
}

const Dispu_Resolved =()=>{
  const isMobile = useMediaQuery({query:`(max-width:600px)`})
  return(
    <div className='disputed_resolved_hero_container'>
      {dispu_resolved.map((item,index)=>{
        return(
          <div key={index} style={{color:item.color,backgroundColor:item.bgColor, borderTop:`3px solid ${item.color}`}}>
            <div className='disputed_resolved_left'>
              <h2 style={{color:item.color}}>{item.amount}</h2>
              <p style={{color:item.color}}>{item.name}</p>
            </div>
            <div className='disputed_resolved_right' style={{backgroundColor:item.rateBgColor}}>
            <ShowChartIcon fontSize={isMobile?'small':'medium'}/>
            </div>
          </div>
        )
      })}
    </div>
    )
}

const OverviewRow3 =()=>{
const isMobile = useMediaQuery({query:`(max-width:600px)`})

{/*Beacuse the component alignment of the mobile and desktop versions are not same....this arranges them to their corresponding figma positions*/}
{/*Material UI Grid Component doing its Majic*/}
  return(
    isMobile?(
      <div className='overview-row3'>
        <TotalContracts/>
        <Dispu_Resolved/>
        <TopSales_Tickets/>
        <TopSales_graph/>
        <AdsRecordsGraph/>
      </div>
    ):
    (
      <div style={{marginTop:'20px' }}>
<Grid container spacing={1.5}>
    <Grid item lg={2.5} md={3} sm={3} xs={12}>
        <TopSales_Tickets/>
        <TopSales_graph/>
        <AdsRecordsGraph/>  
    </Grid>
    <Grid item lg={9.5} md={9} sm={9} xs={12} >
        <Grid container spacing={1.5}>
            <Grid item lg={6} md={12} sm={12} xs={12} >
                <TotalContracts/>
                <Dispu_Resolved/>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
                <FeedBack/>
            </Grid>
        </Grid>
    </Grid>
</Grid>
</div>
    ) 

  )
}



export const Overview=()=>{
  const isMobile = useMediaQuery({query:`(max-width:600px)`})
  const isSmallScreen = useMediaQuery({query:`(max-width:1200px)`})
  return (
    isMobile?(
      <div className='Overview'>
      <CssBaseline/>
          <h2>Overview</h2>
          <OverviewRow1 />
          <OverviewRow2 />
          <OverviewRow3/>
          <TransactionsHistory />
          <FeedBack/>
          <LocationStats/>
          
      </div>
    ): isSmallScreen?(
    <div className='Overview'>
            <CssBaseline/>
            <h2>Overview</h2>
            <OverviewRow1 />
            <OverviewRow2 />
            <OverviewRow3/>
            <TransactionsHistory />   
    </div>
    ):(
      <div className='Overview'>
            <CssBaseline/>
            <h2>Overview</h2>
            <OverviewRow1 />
            <OverviewRow2 />
            <OverviewRow3/>
             <TransactionsHistory />
      </div>
    )
  )
}

export default Overview


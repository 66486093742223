import React from "react";
import profile from '../../../assets/profile.png';
import './feedback.css';
import MessageIcon from '@mui/icons-material/Message';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
export default function Feedback() {
    return(
        <>
        <div className="feedback-wrapper">
            <div className="container">
            <h2>Our Clients Feedback</h2>
            <div className="feedback-wrap">

            
            <div className="feedback-box">
             <span className="feedb-img">
                   <img src={profile} alt="" />
             </span>
                <div className="feedb-text">
                    <h4 className="feedback-name">Ethel <span>@ethel on</span> SDomain name for </h4>
                    <p className="feedback">Great work 😊</p>
                    <span className="feedb-time">1h</span>
                    <div className="feedback-action">
                        <MessageIcon/>
                        <FavoriteBorderIcon/>
                        <InsertLinkIcon/>
                    </div>
                </div>
            </div>
            <div className="feedback-box">
             <span className="feedb-img">
                   <img src={profile} alt="" />
             </span>
                <div className="feedb-text">
                    <h4 className="feedback-name">Ethel <span>@ethel on</span> SDomain name for </h4>
                    <p className="feedback">Great work 😊</p>
                    <span className="feedb-time">1h</span>
                    <div className="feedback-action">
                        <MessageIcon/>
                        <FavoriteBorderIcon/>
                        <InsertLinkIcon/>
                    </div>
                </div>
            </div>
            <div className="feedback-box">
             <span className="feedb-img">
                   <img src={profile} alt="" />
             </span>
                <div className="feedb-text">
                    <h4 className="feedback-name">Ethel <span>@ethel on</span> SDomain name for </h4>
                    <p className="feedback">Great work 😊</p>
                    <span className="feedb-time">1h</span>
                    <div className="feedback-action">
                        <MessageIcon/>
                        <FavoriteBorderIcon/>
                        <InsertLinkIcon/>
                    </div>
                </div>
            </div>
            <div className="feedback-box">
             <span className="feedb-img">
                   <img src={profile} alt="" />
             </span>
                <div className="feedb-text">
                    <h4 className="feedback-name">Ethel <span>@ethel on</span> SDomain name for </h4>
                    <p className="feedback">Great work 😊</p>
                    <span className="feedb-time">1h</span>
                    <div className="feedback-action">
                        <MessageIcon/>
                        <FavoriteBorderIcon/>
                        <InsertLinkIcon/>
                    </div>
                </div>
            </div>
            <div className="feedback-box">
             <span className="feedb-img">
                   <img src={profile} alt="" />
             </span>
                <div className="feedb-text">
                    <h4 className="feedback-name">Ethel <span>@ethel on</span> SDomain name for </h4>
                    <p className="feedback">Great work 😊</p>
                    <span className="feedb-time">1h</span>
                    <div className="feedback-action">
                        <MessageIcon/>
                        <FavoriteBorderIcon/>
                        <InsertLinkIcon/>
                    </div>
                </div>
            </div>
            <div className="feedback-box">
             <span className="feedb-img">
                   <img src={profile} alt="" />
             </span>
                <div className="feedb-text">
                    <h4 className="feedback-name">Ethel <span>@ethel on</span> SDomain name for </h4>
                    <p className="feedback">Great work 😊</p>
                    <span className="feedb-time">1h</span>
                    <div className="feedback-action">
                        <MessageIcon/>
                        <FavoriteBorderIcon/>
                        <InsertLinkIcon/>
                    </div>
                </div>
            </div>
            <div className="feedback-box">
             <span className="feedb-img">
                   <img src={profile} alt="" />
             </span>
                <div className="feedb-text">
                    <h4 className="feedback-name">Ethel <span>@ethel on</span> SDomain name for </h4>
                    <p className="feedback">Great work 😊</p>
                    <span className="feedb-time">1h</span>
                    <div className="feedback-action">
                        <MessageIcon/>
                        <FavoriteBorderIcon/>
                        <InsertLinkIcon/>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
        </>
    )
}
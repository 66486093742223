import React from 'react'
import imagePlaceHolder from '../../../assets/imagePlaceholder.jpg'
import clock from '../../../assets/clock.svg'
import location from '../../../assets/location.svg'
import star from '../../../assets/star.svg'
import { Chip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './SingleItem.css'

const Item = ({item,btnColor})=>{
const navigate = useNavigate();
    return(
        <div className='single-market-item'>
            <div className='head'> {/*Header*/}
                <div> {/*Left Section*/}
                    <p className='p-18 name'>{item.name}</p>
                    <p className='p-13 nickName'>{item.user}</p>
                </div>
                <div> {/*Right Section*/}
                    <Chip label={item.category}/>
                </div>
            </div>
            <div className='below-head'>
             <img src={imagePlaceHolder} id='itemPic'/> 
                {/*Footer section... with Location,time and rating*/}
                <div className='single-item-foot'>{/*icons and all*/}
                    <div>
                        <img src={location} alt="location"/>
                        <p>{item.location}</p>
                    </div>
                    <div>
                        <img  src={clock} alt="sinceDays"/>
                        <p>{item.datePosted}</p>
                    </div>
                    <div>
                        <img src={star} alt="rating" />
                        <p>{item.rating}</p>
                    </div>
                </div>
                </div>
                <div className='buy-now'> {/*Buy now*/}
                    <p>{item.amount}</p>
                    <button className='btn'  
                    onClick={()=>navigate(`/Marketplace/${item.id}`)}>Contract</button>
                </div>
            
        </div>
    )
}

export const CateItem = ({cate})=>{
    return(
        <div className='single-category'>
            <h1 style={{textAlign:'center'}}>{cate}</h1>
        </div>
    )
}

export default Item

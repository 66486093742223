import { Avatar } from '@mui/material'
import React from 'react'
import { feedBack } from '../Overview/StaticData'
import MessageIcon from '@mui/icons-material/Message';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import bitcoin from '../../../assets/bitcoin-yellow.svg'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
import './FeedBack.css'


export const FeedBack =()=>{
const navigate = useNavigate()
    return(
      <div className='feedBack-container'>
        <h2>Feedback</h2>
        <div className='comments-container'>
        {feedBack.slice(0,2).map((fed,index)=>
          (
            <div key={index} className='single-feedback-hero'>
              <Avatar src={bitcoin} className='userImage'/>
              <div className='single-feedback-child'>
                <div className='single-feedback-child-header'>
                <p><b>{fed.name}</b><span>{fed.username}</span></p>
                <p>{fed.fedTime}</p>
                </div>
                
                <p><span>On </span><b>{fed.title}</b> </p>
                <div className="comment_body">
                  <p>{fed.comment}</p>
                </div>
                <div className='single-feedback-child-footer'>
                  <MessageIcon/>
                  <FavoriteBorderIcon/>
                  <InsertLinkIcon/>
                </div>
              </div>
            </div>
          ))}
          </div>
          <div style={{display:'flex',justifyContent:'center'}}>
              <button type='submit ' className='trans-btn btn'>View all</button>
          </div>
      </div>
    )
  }

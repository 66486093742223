import React , {useState} from "react";
import { Button } from '@mui/material';
import p2ponline from '../../assets/p2ponline.jpg';
import Bitcoin4 from '../../assets/Bitcoin4.png';
import Bitcoin2 from '../../assets/Bitcoin2.png';
import { useNavigate } from "react-router-dom";

  const DeveloperPay = () => {
  const navigate = useNavigate()
     return(
        <>
          <div className="apiHero">
        <div className="container">
          <div className="columns-wrap">
            <div className="col col-left">
              <h1> P2POnline Pay</h1>
              <p>Secure online payment processing for cars, boats, airplanes, domains & anything of value, in one line of code.</p>
              <div className="buttons-wrap">
              <Button variant='contained' type='submit' onClick={()=>navigate('/signUp')}>Create account</Button>
              <Button variant='contained' type='submit' className="trans-btn btn" onClick={()=>navigate('/Dashboard/Create-contract')}>Create a Contract</Button>
              </div>
            </div>
            <div className="col col-right">
              <img src={p2ponline} alt="p2ponline" />
            </div>
          </div>
        </div>
      </div>


      <div className="img-cta-section bg-gray developerpay-section">
        <div className="container">
          <div className="columns-wrap reverse-column-wrap">
            <div className="col col-left">
              <h2> What is P2POnline Pay?</h2>
              <p>Escrow Pay is the simplest way to add escrow payments to your website, mobile app, online store, classified site or marketplace. With Escrow Pay enabled on your site or your <a href="#">Buy It Now Buttons</a>, your buyers will be redirected to a wizard on Escrow.com where they can complete an escrow transaction in a few easy steps.</p>
                <ul>
                  <li>
                    <span>Easy integration</span>
                    <p>Get started in minutes with our API documentation and button creator</p>
                  </li>
                  <li>
                    <span>Buyer friendly workflow</span>
                    <p>Get started in minutes with our API documentation and button creator</p>
                  </li>
                      <li>
                    <span>Safe and secure</span>
                    <p>Get started in minutes with our API documentation and button creator</p>
                  </li>
                </ul>
              <div className="buttons-wrap">
              <Button variant='contained' type='submit' onClick={()=>navigate('../DeveloperApiDocum')}>Integrate Now</Button>
              </div>
            </div>
            <div className="col col-right">
              <img src={Bitcoin4} alt="b2b" />
            </div>
          </div>
        </div>
      </div>

      <div className="img-cta-section developerpay-section">
        <div className="container">
          <div className="columns-wrap">
            <div className="col col-left developerpay">
              <h2> How do I add Escrow Pay to my website?</h2>
              <p>You can add Escrow Pay to your business with a single API call. Or you can simply create a Buy It Now Button and your buyers will be automatically redirected into the Escrow Pay wizard. To learn more about integrating with Escrow Pay, please view our online documentation via the link below.</p>
              <div className="buttons-wrap">
              <Button variant='contained' type='submit' onClick={()=>navigate('../DeveloperApiDocum')}>Integrate Now</Button>
              </div>
            </div>
            <div className="col col-right">
              <img src={Bitcoin2} alt="b2b" />
            </div>
          </div>
        </div>
      </div>

      <div className="cta-section">
        <div className="container">
          <div className="cta-column-wrap">
            <div className="cta-col">
              <h2> Over $5 billion of transactions protected with P2POnline</h2>
              <p>Escrow.com is the world’s most secure payments method from a counterparty risk perspective- safeguarding both buyer and seller, all funds transacted using escrow are kept in trust.</p>
              <div className="buttons-wrap btn-center">
              <Button variant='contained' type='submit' onClick={()=>navigate('/signUp')}>Create account</Button>
              </div>
            </div>
          </div>
        </div>
      </div>

        </>
     )
  };
  
  export default DeveloperPay;
  




import React from 'react';
import { useForm } from "react-hook-form";
import './style.css';

export default () => {

    const {
        register: register5,
        formState: { errors: errors5 },
        handleSubmit: handleSubmit5,
      } = useForm({
        mode: "onBlur",
      });

      const onSignup = (data) => {
        console.log(JSON.stringify(data.tel));
        alert(`thank you for your message`);
      };

    return(
        <>

          <div className='signup-wrap'>
            <div className='container'>
              <h2 className='center-text smallh1-48'>Se connecter à <span>OnlineP2P</span></h2>
               <p className='center-text'>Welcome back! Please enter your details.</p>
               <form onSubmit={handleSubmit5(onSignup)} className="signup-form" key={1}>
                <div className='col-50 col'>
                  <label>First name</label>
                  <input type="text" placeholder="First name" {...register5("firstName", { required: true, maxLength: 80 })} />
                  {errors5.firstName && <span className='error'>* Fill the required Field</span>}
                </div>
                <div className='col-50 col'>
                  <label>Last name</label>
                  <input type="text" placeholder="Last name" {...register5("lastName", { required: true, maxLength: 100 })} />
                  {errors5.lastName && <span className='error'>* Fill the required Field</span>}
                </div>
                <div className='col-100 col'>
                  <label>My bio</label>
                  <textarea placeholder="My bio" {...register5("bio", {required : true})} />
                  {errors5.bio && <span className='error'>* Describe Your Little Bio</span>}
                </div>
                <div className='col-50 col'>
                  <label>Country</label>
                  <input type="text" placeholder="Country" {...register5("country", {required: true})} />
                  {errors5.country && <span className='error'>* Enter your Country Name</span>}
                </div>
                <div className='col-50 col'>
                  <label>Location</label>
                  <input type="text" placeholder="Location" {...register5("location", {required: true})} />
                  {errors5.location && <span className='error'>* Enter your Location</span>}
                </div>
 
             
                <div className='col-33 col'>
                  <label>Email</label>
                  <input type="text" placeholder="abc@Email.com" {...register5("email", { required: true, pattern: /^\S+@\S+$/i })} />
                  {errors5.email && <span className='error'>* Enter your Email</span>}
                </div>
                <div className='col-33 col'>
                  <label>DOB</label>
                  <input type="date" {...register5("dob" , { required: true })} />
                  {errors5.dob && <span className='error'>* Select Day</span>}
                </div>


                <div className='col-33 col'>
                  <label>Postal Code</label>
                  <input type="text" placeholder="Postal Code" {...register5("postalcode", { required: true})} />
                  {errors5.postalcode && <span className='error'>* Enter your Mobile Number</span>}
                </div>

                <div className='col-50 col'>
                  <label>Phone number</label>
                  <input type="tel" placeholder="+237" {...register5("number", { required: true, minLength: 6, maxLength: 12 })} />
                  {errors5.number && <span className='error'>* Enter your Mobile Number</span>}
                </div>
                <div className='col-50 col'>
                  <label>Account-Type</label>
                  <select {...register5("accounttype" , { required: true })}>
                    <option value="">Select</option>
                    <option value="1">buyer</option>
                    <option value="2">saller</option>
                  </select>
                  {errors5.accounttype && <span className='error'>* Select Day</span>}
                </div>

                <div className='col-100 col signup'>
                  <input type="submit" className='btn' value="change" />
                </div>
              </form>
          </div>
          </div>
        </>
    )
}

import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CopyBlock, dracula } from "react-code-blocks";
import './codeblock.css';

import sample from './codes' //Change content of file as soon as backend is ready!

function CodeBlocksComp () {
const languages = Object.keys(sample);
const [currentLanguage,setCurrentLanguage] = useState(languages[0]);
    return (
    <div className="codeblock-Tabs">
        <Tabs onSelect={(index) => setCurrentLanguage(languages[index])}>
            <TabList>
                {languages.map((lang,index)=>{
                    return(
                        <Tab key={index}>{lang}</Tab>
                    )
                })}
            </TabList>
            {languages.map((currentLang,index)=>{
                return(
                    <TabPanel key={index}>
                        <CopyBlock
                            language={currentLang}
                            text={sample[currentLang]}
                            showLineNumbers={true}
                            theme={dracula}
                            wrapLines={true}
                            codeBlock
                        />
                    </TabPanel>
                )
            })}
        </Tabs>
    </div>
    )
};
export default CodeBlocksComp;




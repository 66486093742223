import { useEffect } from "react";
import { useLocation } from "react-router-dom";


{/*Due to the fact that we navigate between pages with react-router-dom, i noticed when we navigate to a certain page the browser brings us to the bottom of the page instead of the top....The component below would be imported to index.js so its applied on every navigation...Its function is to bring us to the top of page on link click*/}

export default function TopScrollAuto() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

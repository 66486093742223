import './Header.css'
import React from 'react'
import {CssBaseline
    ,Button
    ,Accordion
    ,AccordionSummary
    ,AccordionDetails
    , Avatar
    ,Rating
    ,Drawer
    } from '@mui/material';
import { useMediaQuery } from 'react-responsive'
import {NavLink
  ,useLocation
  ,useNavigate
} from 'react-router-dom'
import {
     HighlightOffOutlined
    ,ExpandMore
    ,DashboardCustomize
    ,Note
    ,GridViewRounded} from '@mui/icons-material'
import Language from '../../../assets/Language.svg'
import Search from '../../../assets/SearchIcon.svg'
import MenuIcon from '../../../assets/Menu.svg'
import siteIcon from '../../../assets/pageIcon.svg'
import HeaderDown from '../../../assets/HeaderArrowDown.svg'
import { UserActions } from '../../Dashboard/UserActions/User';
// import { UserActions } from '../DashBoard/UserActions/User';

const isSignIn = true //Change this when backend is ready to determine if user is logged in or not


//Mobile Header with navigation
const MobileNav=({handleNav,setShowNav})=>{ 
const navigate = useNavigate()
const location=useLocation()


const MarketPlaceCategories =()=>{
return(
<div className='marketplace-category-list'> 
{/*Show market place category if user is on market place page*/}
            <Accordion elevation={0} disableGutters classes={{root:'user-info-dropdown-box'}}>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <div className='marketplace-dropDown'>
                          <GridViewRounded className='btnIcon'/>
                        <p>See all categories</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <p>single category</p>
                    <p>single category</p>
                    <p>single category</p>
                    <p>single category</p>
                </AccordionDetails>
            </Accordion>
          </div>
)
}



//-----Check if user is in the marketPlace route so it can add the view all categories buttom amongst other buttons
const isMarketPlace = location.pathname.includes('Marketplace')
//------
return(
<div id='mobileNav'>
<div className='menu-icon-close-box'>
    <HighlightOffOutlined className='menu-icon-close' onClick={handleNav}/>
</div>
{isSignIn?(
    <div>
      <Accordion 
      elevation={0}
      disableGutters={true}
      classes={{root:'user-info-dropdown-box'}} >
          <AccordionSummary expandIcon={<ExpandMore />}>
              <div className='signed-in-user-info'>
                  <div className='signed-in-user-info1'>
                      <Avatar classes={{root:'signed-in-user-avatar'}}/>
                      <div className='signed-in-user-info2'>
                          <p className='user-infor-name'>Jhon Doe</p>
                          <p className='user-infor-position'>Financial expert</p>
                      </div>
                  </div>
                  <div>
                      <Rating readOnly value={3} max={4} classes={{root:'user-info-rating'}}/>
                  </div>
              </div>
          </AccordionSummary>
          <AccordionDetails >
              <UserActions/>
          </AccordionDetails>
      </Accordion>
      <div>
          <Button variant='contained' classes={{root:'mobile-muiBtn-contained'}}  fullWidth onClick={()=>{setShowNav(false); navigate('Dashboard/Create-contract')}}>
            <DashboardCustomize className='btnIcon'/>
          Create new escrow
          </Button>
          <Button variant='contained' classes={{root:'mobile-muiBtn-contained'}} style={{backgroundColor:'#04063d'}}fullWidth>
            <Note className='btnIcon'/>
              Generate a report
          </Button>
          {isMarketPlace &&  <MarketPlaceCategories/>}
      </div>
    </div>
):(
<div className='not-signed-in'>
  <Button variant='contained' classes={{root:'desktop-muiBtn-contained'}}>Sign In</Button>
</div>
)}
<MobileLinkss setShowNav={setShowNav}/>
</div>
)
}


const MobileLinkss =({setShowDrawer,setShowNav})=>{
const navClickHandler=()=>{
setShowNav(false)
if(setShowDrawer){
  setShowDrawer(false)
}
}
return ( 
<div>{/*Mobile nav links*/}
        <NavLink to='/' className='RouteLink' onClick={navClickHandler}>Home</NavLink>
        <NavLink to='/Marketplace' className='RouteLink'  onClick={navClickHandler}>Marketplace</NavLink>
        <NavLink to='/Dashboard' className='RouteLink' onClick={navClickHandler}>Dashboard</NavLink>
        <Accordion classes={{root:'smallerAccordion'}} elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ExpandMore />} style={{marginTop:'-10px',padding:'0px'}}>
            <p className='RouteLink' style={{margin:'0px'}}>Developer</p>
          </AccordionSummary>
          <AccordionDetails style={{margin:'0px',padding:'0px'}}>
            <NavLink to='/' className='smallerLinks' onClick={navClickHandler}>API Integration</NavLink>
            <NavLink to='/' className='smallerLinks' onClick={navClickHandler}>API Documentation</NavLink>
            <NavLink to='/' className='smallerLinks' onClick={navClickHandler}>Escrow pay</NavLink>
          </AccordionDetails>
        </Accordion>
        <Accordion classes={{root:'smallerAccordion'}} elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <p className='RouteLink'>Help</p>
          </AccordionSummary>
          <AccordionDetails >
            <NavLink to='/' className='smallerLinks' onClick={navClickHandler}>Contact</NavLink>
            <NavLink to='/' className='smallerLinks' onClick={navClickHandler}>About us</NavLink>
            <NavLink to='/' className='smallerLinks' onClick={navClickHandler}>Help</NavLink>
          </AccordionDetails>
        </Accordion>
        </div>
)
}

{/*Desktop Nav links*/}
const DesktopLinks = ()=>{
return(
  <div className='Nav-links-left'> 
      <NavLink to='/' className={({isActive})=>isActive?"Single-header-active-link":"Single-header-link"}>Home</NavLink>
      <NavLink to='/Dashboard' className={({isActive})=>isActive?"Single-header-active-link":"Single-header-link"}>Dashboard</NavLink>
      <NavLink to='/MarketPlace' className={({isActive})=>isActive?"Single-header-active-link":"Single-header-link"}>MarketPlace</NavLink>

      <NavLink to='/Developer' className={({isActive})=>isActive?"Single-header-active-link":"Single-header-link"}>Developer</NavLink>

      <NavLink to='/Help' className={({isActive})=>isActive?"Single-header-active-link":"Single-header-link"}>Help </NavLink>
    </div>
)
}

{/*Laptop header and navigation*/}
const LaptopDisplay = ()=>{ 
const handleDrawer = ()=>{
setDrawer(prev=>!prev)
}
const navigate = useNavigate()
const [openDrawer,setDrawer] = React.useState(false);
return(
<div className='HeaderBox'>
    <div className='Logo-box'>
        <img src={siteIcon} alt="Fairlyx logo" />
        <img src={MenuIcon} alt="" onClick={handleDrawer} className='ipaMenuIcon'/>
    </div>
    <div className='Accordion-Open'> {/*left drawer for small screens displays*/}
        <Drawer open={openDrawer}>
          <div>
            <HighlightOffOutlined className='side-bar-icon-close' onClick={handleDrawer}/>
          </div>
          <div className='content-box'>
            <MobileNav/>
          </div>
        </Drawer>
    </div>
    <div> {/*Nav-links-left*/}
      <DesktopLinks/>
    </div>
    <div className='Nav-links-right' >
        <img src={Search}  alt='iconSVG'/>
        <img src={Language}  alt='iconSVG'/>
        <img src={HeaderDown}  alt='iconSVG'/>
        <div className='auth'>
            <Button style={{color:'#73779F'}} onClick={()=>navigate('/signIn')}>Sign In</Button>
            <Button variant='outlined' onClick={()=>navigate('/signUp')}>Sign up</Button>
        </div>
    </div>
</div>
)
}


{/*Mobile header and navigations*/}
const MobileDisplay=()=>{
const [showNav,setShowNav] = React.useState(false)
const handleNav = ()=>{
setShowNav(!showNav)
}



{/*Hook to prevent page scrolling when navbar is open*/}
React.useEffect(() => { 
showNav && (document.body.style.overflow = 'hidden')
   !showNav && (document.body.style.overflow = 'unset')
}, [showNav]);



return(
<div className='Mobile-HeaderBox'>
<div className='Mobile-Header-right' onClick={handleNav}>
    <img src={MenuIcon} alt='iconSVG'/>
</div>
<div className='Mobile-Header-left'>
    <img src={Language}  alt='iconSVG'/>
    <img src={HeaderDown}  alt='iconSVG'/>
</div>
{showNav && <MobileNav handleNav={handleNav} setShowNav={setShowNav}/>}
</div>
)
}







function Header() {
const isMobile = useMediaQuery({query:`(max-width:600px)`})
return(
<div className='header-wrapper'>
  <CssBaseline/>
  {isMobile?<MobileDisplay/>:<LaptopDisplay/>}
</div>
)
}

export default Header



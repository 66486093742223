import React from 'react'
import { Grid } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useMediaQuery } from 'react-responsive'
import './Footer.css'


function Footer() {
  const isMobile = useMediaQuery({query:`(max-width:600px)`})
  const [email,setEmail] = React.useState("")
  const handleSubmit = (e)=>{
    e.preventDefault()
    alert(`Email is : ${email}`)
  }
  return (
    <div className='page-wrapper'>
      
    <div className='Footer'>
     <div className='footer-container'>

      <div className='blurry-background blur-1'></div>
      <div className='blurry-background blur-2'></div>
      <div className='blurry-background blur-3'></div>
      <div>
        <h2 className='Bigh2-34 title'>
        Subscribe to our Newsletter and learn more
          about our updated services
        </h2>
        <div>
          <form className='emialInput'>
            <input 
              type="text" 
              placeholder='Your email...' 
              />
            <button type='submit'>
              Subscribe
            </button>
          </form>
         
        </div>
      </div>
      <div className='footer-links-container'>
          <Grid container spacing={0}>
          <Grid item lg={2.4} md={4} sm={6} xs={12}>
          <h2 className='smallh2-12 linkTitle'>ONLINE P2P</h2>
              <div>
              <NavLink to='/Info/AboutUs' className='footerLink'>About Us</NavLink>
              <NavLink to='/Developer/DeveloperApiDocum' className='footerLink'>Developer-API</NavLink>
              <NavLink to='/' className='footerLink'>How are we different</NavLink>
              <NavLink to='/Developer/DeveloperPay' className='footerLink'>Developer pay</NavLink>
              <NavLink to='/Info/MessageCenter' className='footerLink'>Message Center</NavLink>
              </div>
          </Grid>
          <Grid item lg={2.4} md={4} sm={6} xs={12}>
          <h2 className='smallh2-12 linkTitle'>USEFUL LINKS</h2>
              <div>
              <NavLink to='/followers/Follow' className='footerLink'>Follower</NavLink>
              <NavLink to='/followers/Followings' className='footerLink'>Followings</NavLink>
              <NavLink to='/Info/Contact' className='footerLink'>Contact</NavLink>
              <NavLink to='/PrivacyPolicy/Privacypolicy' className='footerLink'>Privacy</NavLink>
              <NavLink to='/PrivacyPolicy/TermsCond' className='footerLink'>Terms and Conditions</NavLink>
              </div>
          </Grid>
          <Grid item lg={2.4} md={4} sm={6} xs={12}>
          <h2 className='smallh2-12 linkTitle'>RESOURCES</h2>
              <div>
              <NavLink to='/aboutUS' className='footerLink'>Blog</NavLink>
              <NavLink to='/aboutUS' className='footerLink'>Case Studies</NavLink>
              </div>
          </Grid>
          <Grid item lg={2.4} md={4} sm={6} xs={12}>
          <h2 className='smallh2-12 linkTitle'>SUPPORT</h2>
              <div>
              <NavLink to='/Help/' className='footerLink'>Help Center</NavLink>
              
              </div>
          </Grid>
          <Grid item lg={2.4} md={4} sm={6} xs={12}>
          <h2 className='smallh2-12 linkTitle'>LOCATION</h2>
              <div>
              <NavLink to='/aboutUS' className='footerLink'>360 Spear St Floor 4,
               Nkoulouloun, DLA 94105</NavLink>
              </div>
          </Grid>

          </Grid>
      </div>
      <div className='cpyBox'>
        <p>© Copyright 2021. All Rights Reserved.</p>
        {!isMobile && <div className='footer-socialMediaLinks'>
          <a href="https://www.google.com" ><LinkedInIcon/></a>
          <a href="https://www.google.com" ><FacebookIcon/></a>
          <a href="https://www.google.com" ><TwitterIcon/></a>
        </div>}
      </div>
    </div>
    </div>
    
    </div>
  )
}

export default Footer
            
            
            
import {Chip} from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from 'react' 
import './ThirdView.css'

export const ThirdView =()=>{

const whyChooseusReasons =[ 
  {title:"Pro services",body:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada at faucibus vitae libero tellus enim eros, tristique. Lorem aliquet nunc bibendum."},
  {title:"Low Costing",body:"You can easily escrow vehicles, websites, domain names, jewellery, intellectual property, and nearly anything else you can ."},
  {title:"Live Support",body:"Customer support has been made available to you 24hours a day 7days a week, contact us any time and we are there to respond."},
  {title:"Safe and secure",body:"We are the only government regulated and licensed escrow company online that is compliant with escrow Law. "}
]
  return(
    <div className="page-wrapper">
    <div className='ThirdView'>
        <div>
          <div className='titleContainer'>
        <div>
          <div className='blurry-background blur-10'></div>
          <Chip label="We explain"/>
          <h1 className='smallh1-48 heading centerd'>Why choose us?</h1>
          <p className='p-18 smTitle' >We're not just an online escrow service, We're THE BEST online escrow service.</p>
        </div>
      </div>
      <div className='reasons-container'>
          {whyChooseusReasons.map((reason,index)=>(
            <div className='single-reason-container' key={index} index={index}>
              <h3 className='h3-24 boxTitle' index={index}>{reason.title}</h3>
              <p className='p-16 body'>{reason.body}</p>
              <div className='flex-div'>
              <p className='p-18 view-more'>Learn More</p> <KeyboardArrowRightIcon classes={{root:'mui-right-icon'}}/>
              </div>
            </div>
          ))}
      </div>
        </div>
    </div>
    </div>
  )
}

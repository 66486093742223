import './Contact.css';
import React from 'react';
import Map from './Map';
import { useForm } from "react-hook-form";


export default function Contact(){
    const {
        register: register10,
        formState: { errors: errors10 },
        handleSubmit: handleSubmit10,
      } = useForm({
        mode: "onBlur",
      });

      const contact = (data) => {
        console.log(JSON.stringify(data.tel));
        alert(`thank you for your message`);
      };
  return(
    <>
       <div className="contacts-wrapper">
        <div className="container">
            <div className="text-container">
                <h1>Contact Us</h1>
                <span>We're here to help.</span>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo quidem magni voluptas cupiditate, asperiores officia, voluptatum iure nihil, voluptates recusandae nobis aut iste. Corporis consequuntur aliquam ullam reprehenderit culpa nobis.</p>
            </div>
            <div className="two-columns locations">
                <div className="col-left col">
                    <h2>Our Location</h2>
                    <div className="text-wrap">
                        <p><b>Location</b>: 360 Spear St Floor 4, Nkoulouloun, DLA 94105</p>
                        <p><b>Tel</b>: +87584758475</p>
                        <p><b>Fax</b>: +5654685685</p>
                    </div>
                </div>
                <div className="col-right col">
                   <Map />
                </div>
            </div>
            <div className="contact-section">
            <div className="text-container">
                <h1>Get In Touch</h1>
                <p>Do you need any help Let'us Be Know! </p>
            </div>
             <form key={10} onSubmit={handleSubmit10(contact)} className="contact-form">
                <label>Your Name</label>
                <input type="text" placeholder="Enter personal email address" {...register10("name", { required: true })} />
                {errors10.name && <span className='error'>This field is required</span>}
                <label>Your Email</label>
                <input type="email" placeholder="Enter personal email address" {...register10("email", { required: true })} />
                {errors10.email && <span className='error'>This field is required</span>}
                 <label >*Leave a Message</label>
                  <textarea placeholder="Your Message" {...register10("Message", {required : true})} />
                  {errors10.Message && <span className='error'>* Describe Your Little Bio</span>}
                <input type="submit" />
              </form>
            </div>
        </div>
       </div>
    </>
  )
}

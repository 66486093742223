import React , {useState} from 'react';
import './messagecenter.css';
// import { UserActions, UserInfo } from '../../DashBoard/UserActions/User';
import profile from '../../../assets/profile.png';
 export default function MessageCen () {
    // const [condition, setCondition] = useState(false);
    // const handleClick = event => {
    //     if(event.currentTarget.nextSibling.classList.contains('.active')){
    //         event.currentTarget.nextSibling.classList.remove('active');
    
    //     }
    //     else{
    //         event.currentTarget.previousSibling.classList.remove('active');
       
    //     }

    //     event.currentTarget.classList.add('active');
    // };
    return(
      <>
       <div className="msg-center-section">
          <div className='message-side-bar'>
            
          </div>
      
            <div className="msg-main-wrap">
              <h3>Chat Center</h3>
              <div className="msg-wrapper">
                  <div className="msg-profiles">
                      <div className="state-btns">
                          <div className="sta-btn active" onClick={(e) => {
                                 
                                e.target.classList.add('active');
                                e.target.nextSibling.classList.remove('active');
                          } }>
                                <i className="fa-solid fa-user"></i>
                                 Peer to peer
                                <span className='chat-user-no'>2</span>
                          </div>
                          <div className="sta-btn" onClick={(e) => {
                                 
                                 e.target.classList.add('active');
                                 e.target.previousSibling.classList.remove('active');
                           } }>
                              <i className="fa-solid fa-globe"></i>
                                Global
                                <span className='chat-user-no'>2</span>
                          </div>
                      </div>
                      <div className="profiles">
                          <div className="profile">
                              <div className="profile-img">
                                  <img src={profile} alt="profile" />
                                  <span className="profile-active"></span>
                              </div>
                              <div className="profile-content">
                                  <div className="profile-name">
                                      profile name
                                      <span className="chat-time">
                                          09:00AM
                                      </span>
                                      <span className="online-status active"></span>
                                  </div>
                                  <div className="profile-desc">
                                      <p>When do you release the coded...</p>
                                  </div>
                              </div>
                          </div>
                          <div className="profile">
                              <div className="profile-img">
                                  <img src={profile} alt="profile" />
                                  <span className="profile-active"></span>
                              </div>
                              <div className="profile-content">
                                  <div className="profile-name">
                                      profile name
                                      <span className="chat-time">
                                          09:00AM
                                      </span>
                                      <span className="online-status"></span>
                                  </div>
                                  <div className="profile-desc">
                                      <p>When do you release the coded...</p>
                                  </div>
                              </div>
                          </div>
                          <div className="profile">
                              <div className="profile-img">
                                  <img src={profile} alt="profile" />
                                  <span className="profile-active"></span>
                              </div>
                              <div className="profile-content">
                                  <div className="profile-name">
                                      profile name
                                      <span className="chat-time">
                                          09:00AM
                                      </span>
                                      <span className="online-status active"></span>
                                  </div>
                                  <div className="profile-desc">
                                      <p>When do you release the coded...</p>
                                  </div>
                              </div>
                          </div>
                          <div className="profile">
                              <div className="profile-img">
                                  <img src={profile} alt="profile" />
                                  <span className="profile-active"></span>
                              </div>
                              <div className="profile-content">
                                  <div className="profile-name">
                                      profile name
                                      <span className="chat-time">
                                          09:00AM
                                      </span>
                                      <span className="online-status"></span>
                                  </div>
                                  <div className="profile-desc">
                                      <p>When do you release the coded...</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="search-chat">
                          <i className="fa-solid fa-magnifying-glass"></i>
                          <input type="search" placeholder="Search message"/>
                      </div>
                  </div>

                  <div className="profchat-tab ">
                      <div className="chat-topbar">
                          <div className="chat-title">
                              <h3>Takam Casper</h3>
                              <span className="chat-amount chat-subdes">Amount : <b>130.00$</b></span>
                              <span className="chat-status chat-subdes">Status : <b>Not accepted </b></span>
                          </div>
                          <div className="chat-paybtn">
                              <i className="fa-solid fa-magnifying-glass"></i>
                              <button>Pay Transaction</button>
                          </div>
                      </div>
                      <div className="chatting-tab">
                          <div className="profiles">
                              <button className="load-convers">Load conversation</button>
                              <span className='chat-date'>20 Oct. 2022</span>
                                  <div className="profile">    
                                      <div className="profile-img">
                                          <img src={profile} alt="profile" />
                                      </div>
                                      <div className="profile-content">
                                          <div className="profile-name">
                                              profile name
                                          </div>
                                          <div className="profile-desc">
                                              <p>When do you release the coded for the Fleet - Travel kit? <a href="#">https://product-link</a></p>
                                              <span className="chat-time">
                                                  09:00AM
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="profile supporter-chat">    
                                      <div className="profile-img">
                                          <img src={profile} alt="profile" />
                                      </div>
                                      <div className="profile-content">
                                          <div className="profile-name">
                                              profile name
                                          </div>
                                          <div className="profile-desc">
                                              <p>When do you release the coded for the Fleet - Travel kit? <a href="#">https://product-link</a></p>
                                              <span className="chat-time">
                                                  09:00AM
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              
                              <div className="reply-buttons">
                                  <div className="send-buttons">
                                      <button><i className="fa-regular fa-file"></i></button>
                                      <button className='smily-btn'><i className="fa-regular fa-face-smile"></i></button>
                                      <button><i className="fa-solid fa-circle-exclamation"></i></button>
                                  </div>
                                  <div className="send-field">
                                      <textarea placeholder="Text..." contenteditable="true"></textarea>
                                      <button className="send">
                                          Send
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
      </div>
      </>
    );
}


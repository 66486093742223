import React from "react";
import './testimonial.css';
import testi from '../../../assets/testi.jpg';
export default function Testimonial() {
    return (
        <>
            <div className="testi-section">
                <div className="container">
                    <h1>Testimonial</h1>
                    <div className="testi-wrap">
                        <div className="testi-box">
                            <div className="testi-content">
                                <h3>Incredible Experience</h3>
                                <p>We had an incredible experience working with Landify and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. It acted as a catalyst to take our design to the next level and get more eyes on our product.</p>
                            </div>
                            <div className="testi-name">
                                <img src={testi} alt="" />
                                <div className="testi-name-cont">
                                    <h4>Rubika Haya</h4>
                                    <span>CEO, ABC Corporation</span>
                                </div>
                            </div>
                        </div>
                        <div className="testi-box">
                            <div className="testi-content">
                                <h3>Incredible Experience</h3>
                                <p>We had an incredible experience working with Landify and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. It acted as a catalyst to take our design to the next level and get more eyes on our product.</p>
                            </div>
                            <div className="testi-name">
                                <img src={testi} alt="" />
                                <div className="testi-name-cont">
                                    <h4>Rubika Haya</h4>
                                    <span>CEO, ABC Corporation</span>
                                </div>
                            </div>
                        </div>
                        <div className="testi-box">
                            <div className="testi-content">
                                <h3>Incredible Experience</h3>
                                <p>We had an incredible experience working with Landify and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. It acted as a catalyst to take our design to the next level and get more eyes on our product.</p>
                            </div>
                            <div className="testi-name">
                                <img src={testi} alt="" />
                                <div className="testi-name-cont">
                                    <h4>Rubika Haya</h4>
                                    <span>CEO, ABC Corporation</span>
                                </div>
                            </div>
                        </div>
                        <div className="testi-box">
                            <div className="testi-content">
                                <h3>Incredible Experience</h3>
                                <p>We had an incredible experience working with Landify and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. It acted as a catalyst to take our design to the next level and get more eyes on our product.</p>
                            </div>
                            <div className="testi-name">
                                <img src={testi} alt="" />
                                <div className="testi-name-cont">
                                    <h4>Rubika Haya</h4>
                                    <span>CEO, ABC Corporation</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

import { Grid } from "@mui/material";
import Listings from "./Listings/Listings";
import TotalSubs from "./TotalSubs/TotalSubs";
import TotalUsers from "./Totalusers/TotalUsers";
import UserStats from "./UserStats";
import './Dashboard-style.css'


const DashBoard =()=>{
    return(
        <Grid container spacing={2}>
            <Grid item md={2.5} lg={2.5} xl={2.5} sx={{display:{xs:'none',lg:'block',xl:'block',md:'none',sm:'none'}}}>
                <div className="admin_user_stats">
                    <UserStats/>
                </div>
            </Grid>
            <Grid item md={12} lg={9.5} xl={9.5} xs={12} sm={12}>
                <div className="Admin_dashboard">
                    <TotalUsers/>
                    <TotalSubs/>
                    <Listings/>
                </div>
            </Grid>
        </Grid>
    // <>
    // <div className="Admin_user_stats">
    //     <UserStats/>
    // </div>
    // <div className="Admin_dashboard">
    //     
    //     
    //     
    // </div>
    // </>
        )
}

export default DashBoard

import React from 'react';
import { TabList, Tab, Tabs, TabPanel } from "react-tabs"
import CodeBlocksComp from "./CodeBlock/CodeBlocksComp";

const DeveloperApiDocum = ()=>{
    return (
        <>

            <div className="devloper-tab">
               <Tabs>
                    <TabList >
                        <Tab className=' react-tabs__tab main-tab-hd'>Escrow API <br /> Documentation</Tab>
                        <Tab> Escrow API Basics </Tab>
                        <Tab> Create a customer</Tab>
                        <Tab> Update a customer </Tab>
                        <Tab> Escrow API Basics </Tab>
                        <Tab> Create a customer</Tab>
                        <Tab> Update a customer </Tab>
                        <Tab className=' react-tabs__tab main-tab-hd'>Escrow API <br /> Documentation</Tab>
                        <Tab> Escrow API Basics </Tab>
                        <Tab> Create a customer</Tab>
                        <Tab> Update a customer </Tab>
                        <Tab> Escrow API Basics </Tab>
                        <Tab> Create a customer</Tab>
                        <Tab> Update a customer </Tab>
                    </TabList>
                    <TabPanel>
                      <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a Transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>Choose your favourite client from the samples below.</li>
                            <li>Choose your favourite client from the samples below.</li>
                            <li>Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                      <CodeBlocksComp />
                        <div className="devloper-next-pages-links">
                            <h3>Next Steps</h3>
                            <a href="#"><i class="fa-solid fa-arrow-up"></i>CREATE A TRANSACTION</a>
                            <a href="#"><i class="fa-solid fa-arrow-up"></i>CREATE A CUSTOMER</a>
                            <a href="#"><i class="fa-solid fa-arrow-up"></i>FULL API REFERENCE DOCUMENT</a>
                        </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                    <TabPanel >
                    <h1>Escrow API Quick Start Guide</h1>
                      <h2>Creating a transaction</h2>
                      <div className="list-items">
                        <p>Create your first transaction with the Escrow API in seconds.</p>
                        <ol>
                            <li>1. Choose your favourite client from the samples below.</li>
                            <li>2. Choose your favourite client from the samples below.</li>
                            <li>3. Choose your favourite client from the samples below.</li>
                        </ol>
                      </div>
                    </TabPanel>
                </Tabs>
                  
            </div>

            {/* < CodeBlocksComp/> */}
        </>
    )
}
//  const Lol =()=>{
//   return(
//     <div>
//       <h1>
//         HELLO THERE
//       </h1>
//     </div>
//   )
// }

export default DeveloperApiDocum;

import React from 'react'
import Item from '../AnItem/Item';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import './EscrowMarket.css'

const Popular =({alltheItems})=>{
  const isMobile = useMediaQuery({query:`(max-width:600px)`})
  const isMediumScreen = useMediaQuery({query:`(max-width:1200px)`})
  const isSmallScreen = useMediaQuery({query:`(max-width:915px)`})
  const popular_escrows = alltheItems.filter(item=>item.popular)
  const [currentSlide,setCurrentSlide] = React.useState(0)
  const checkScreenForSlider=()=>{
    if(isMobile){
      return 1;
    }
    else if(isSmallScreen){
      return 2
    }
    else if(isMediumScreen){
      return 3
    }
    
    else {
      return 3
    }
  }
  var settings = {
    beforeChange:function(prev,next){
      setCurrentSlide(next)
    },
    customPaging: function(pagi, i) {
      const style={
        height:'10px',
        width:'10px',
        borderRadius:'100px',
        border:'2px solid #82839e',
        marginTop:"24.6px",
        marginBottom:'36px',
      }
      const activeStyle={
        height:'10px',
        width:'10px',
        borderRadius:'100px',
        backgroundColor:'#04063d',
        marginTop:"24.6px",
        marginBottom:'36px',
      }
      return (
        <div className='slick-dot' style={pagi===currentSlide?activeStyle:style}> </div>
      );
    },
    dots:true,
    infinite:true,
    speed:1000,
    slidesToShow:checkScreenForSlider(),
    slidesToScroll:1,
    arrows:false,
    autoplay:true,
    autoplayspeed:500
  
  }
  return (
    <div className='marketplace-slider-container'>
                <Slider {...settings} >
                  {popular_escrows.map(escrow=>{return(
                        <Item item={escrow} btnColor='#FF6A55'/>
                  )})}
                </Slider>
    </div>
  )
}


export const EscrowMarketPlace = ({categories,category,setCategory,alltheItems}) => {
  const isMobile = useMediaQuery({query:`(max-width:600px)`})

  const popular_escrows = alltheItems.filter(item=>item.popular)

  const popular_categories =[]

  const filterByCategory = alltheItems.filter(item=> item.category === category)

  popular_escrows.forEach(escrow => {
    if(popular_categories.includes(escrow.category)){
      
    }
    else(
      popular_categories.push(escrow.category)
    )
  })
  // const itemByCategory = alltheItems.filter(item=>item.category === category)
  // console.log(itemByCategory)

  return (
    <div className='escrow-marketplcae-container'>
        <div className='add-conatiner'>
          <div>
            <h1>The Best Platform for Escrow services</h1>
            <p className='p-16'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptates, veritatis distinctio voluptatum tempora.</p>
          </div>
        </div>
        <div> {/*Most popular escrows!*/}
          <div className="popular-escrow"> 
              <p className='p-18'>Most popuar escrows</p>
              {isMobile && <Link to='/'><span>View All</span></Link>}
          </div>
              <Popular alltheItems={alltheItems} className='escrow-slider'/> 
          <div> {/*All the escrows*/}
          <div className='top-escrow'> 
              <p className='p-18'>Top escrows</p>
          </div>
           <Grid container >
            
                    {alltheItems.map((item,index)=>{
                      return(
                        <Grid item xl={3} lg={4} sm={6}  md={4} xs={12}>
                          <div className='Item-container'>
                            <Item item={item} btnColor='#5A48FB' key={index}/>
                          </div>
                        </Grid>
                      )
                    })}
          </Grid>
          </div>
              
        </div>
    </div>
  )
}

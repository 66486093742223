import React from 'react'
import imagePlaceHolder from '../../../assets/imagePlaceholder.jpg'
import { Button, Grid } from '@mui/material'
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import './Sellcontract.css'




const SellProduct =()=>{
    return(
        <div className='single-contract-type'>
            <Grid container spacing={0}>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="Username">Username</label>
                    <input type="text" id='Username' required/>
                </Grid>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="UserId">User Id</label>
                    <input type="text" id='UserId' required/>
                </Grid>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="serviceId">Service ID</label>
                    <input type="text" name="" id="serviceId" required/>
                </Grid>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="location">Location address</label>
                    <input type="text" name="location" id="location" required/>
                </Grid>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="PaymentId">Payment ID</label>
                    <input type="text" id='PaymentId' required/>
                </Grid>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="UserID">Payment Method</label>
                    <select id='UserId'>
                        <option value="Mobile Money">MTN</option>
                        <option value="Orange Money">Orange</option>
                        <option value="Paypal">Paypal</option>
                        <option value="Credit Card">Bank</option>
                    </select>
                </Grid>
            </Grid>
            <p>Product Details</p>
            <div className="secondForm">
                <Grid container spacing={2}>
                    <Grid item xl ={4} md ={4} sm ={4} xs={12}>
                        <div className='picDiv'>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus ipsum alias vitae odio</p>
                        </div>
                    </Grid>
                    <Grid item xl ={8} md ={8} sm ={8} xs={12}>
                        <div className='small-input-div'>
                            <Grid container spacing={0}>
                                <Grid item xl={6} md={6} xs ={12}>
                                    <label htmlFor="ServiceName">Service Name</label>
                                    <input type="text" id='ServiceName'/>
                                </Grid>
                                <Grid item xl={6} md={6} xs ={12}>
                                    <label htmlFor="ServiceType">Type</label>
                                    <select id='type'>
                                        <option value="car-part">Car part</option>
                                        <option value="diamonds">diamonds</option>
                                        <option value="houses">houses</option>
                                        <option value="Tech">Tech</option>
                                    </select>
                                </Grid>
                                <Grid item xl={12} md={12} xs ={12}>
                                    <label htmlFor="refNum">Reference number</label>
                                    <input type="text" id='refNum'/>
                                </Grid>
                                <Grid item xl={12} md={12} xs ={12}>
                                    <label htmlFor="description">Reference number</label>
                                    <textarea name="description" id="descript" rows={6}></textarea>
                                </Grid>
                                <Grid item xl={6} md={6} xs ={12}>
                                    <label htmlFor="location">Location</label>
                                    <input type="text" id='location'/>
                                </Grid>
                                <Grid item xl={6} md={6} xs ={12}>
                                    <label htmlFor="ServiceType">status</label>
                                    <select id='type'>
                                        <option value="option">option</option>
                                        <option value="option">option</option>
                                        <option value="option">option</option>
                                        <option value="option">option</option>
                                    </select>
                                </Grid>
                                <Grid item xl={6} md={6} xs ={12}>
                                    <label htmlFor="location">Cost</label>
                                    <input type='number' id='cost'/>
                                </Grid>
                                <Grid item xl={6} md={6} xs ={12}>
                                <label htmlFor="currency">Currency</label>
                                <select id='type'>
                                    <option value="option">option</option>
                                    <option value="option">option</option>
                                    <option value="option">option</option>
                                    <option value="option">option</option>
                                </select>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            
            {/* {
                    Allmilestones.slice(0,3).map((mile,index)=>{
                        return(
                            <MileStone key={index}/>
                        )
                    })
                } */}
        </div>
    )
}




const SellService =()=>{

    return(
        <div className='single-contract-type'>
            <Grid container spacing={0}>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="Username">Username</label>
                    <input type="text" id='Username' required/>
                </Grid>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="UserId">User Id</label>
                    <input type="text" id='UserId' required/>
                </Grid>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="serviceId">Service ID</label>
                    <input type="text" name="" id="serviceId" required/>
                </Grid>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="location">Location address</label>
                    <input type="text" name="location" id="location" required/>
                </Grid>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="PaymentId">Payment ID</label>
                    <input type="text" id='PaymentId' required/>
                </Grid>
                <Grid item xl={6} md={6} xs ={12}>
                    <label htmlFor="UserID">Payment Method</label>
                    <select id='UserId'>
                        <option value="Mobile Money">MTN</option>
                        <option value="Orange Money">Orange</option>
                        <option value="Paypal">Paypal</option>
                        <option value="Credit Card">Bank</option>
                    </select>
                </Grid>
            </Grid>
            <p>Product Details</p>
            <div className="secondForm">
                <Grid container spacing={2}>
                    <Grid item xl ={4} md ={4} sm ={4} xs={12}>
                        <div className='picDiv'>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus ipsum alias vitae odio</p>
                        </div>
                    </Grid>
                    <Grid item xl ={8} md ={8} sm ={8} xs={12}>
                        <div className='small-input-div'>
                            <Grid container spacing={0}>
                                <Grid item xl={6} md={6} xs ={12}>
                                    <label htmlFor="ServiceName">Service Name</label>
                                    <input type="text" id='ServiceName'/>
                                </Grid>
                                <Grid item xl={6} md={6} xs ={12}>
                                    <label htmlFor="ServiceType">Type</label>
                                    <select id='type'>
                                        <option value="car-part">Car part</option>
                                        <option value="diamonds">diamonds</option>
                                        <option value="houses">houses</option>
                                        <option value="Tech">Tech</option>
                                    </select>
                                </Grid>
                                <Grid item xl={12} md={12} xs ={12}>
                                    <label htmlFor="refNum">Reference number</label>
                                    <input type="text" id='refNum'/>
                                </Grid>
                                <Grid item xl={12} md={12} xs ={12}>
                                    <label htmlFor="description">Reference number</label>
                                    <textarea name="description" id="descript" rows={6}></textarea>
                                </Grid>
                                <Grid item xl={6} md={6} xs ={12}>
                                    <label htmlFor="location">Location</label>
                                    <input type="text" id='location'/>
                                </Grid>
                                <Grid item xl={6} md={6} xs ={12}>
                                    <label htmlFor="ServiceType">status</label>
                                    <select id='type'>
                                        <option value="option">option</option>
                                        <option value="option">option</option>
                                        <option value="option">option</option>
                                        <option value="option">option</option>
                                    </select>
                                </Grid>
                                <Grid item xl={6} md={6} xs ={12}>
                                    <label htmlFor="location">Cost</label>
                                    <input type='number' id='cost'/>
                                </Grid>
                                <Grid item xl={6} md={6} xs ={12}>
                                <label htmlFor="currency">Currency</label>
                                <select id='type'>
                                    <option value="option">option</option>
                                    <option value="option">option</option>
                                    <option value="option">option</option>
                                    <option value="option">option</option>
                                </select>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            
        </div>
    )
        
}

const MileStone =({amount,mileamount})=>{
    return(
        amount.slice(0,mileamount).map((amt,index)=>{
            return(
                <div className='milestone-container'>
            <Grid container spacing={1}>
                <Grid item xl={12} md={12} sm={12} lg={12} xs={12}>
                    <label htmlFor="MileName">Milestone name</label>
                    <input type="text" id='MileName'/>
                </Grid>
                <Grid item xl={12} md={12} sm={12} lg={12} xs={12}>
                    <label htmlFor="description">Description of the milestone</label>
                    <textarea name="mileDesc" id="descript" placeholder='milestone Description' rows={6}></textarea>
                </Grid>
                <Grid item xl={12} md={12} sm={12} lg={12} xs={12}>
                    <label htmlFor="Mileobj">Objectives of the milestone</label>
                    <input type="text" id='Mileobj'/>
                </Grid>
                    <div style={{padding:'10px'}}>
                <Grid container spacing={2}>
                {/* <div>Const and duration</div> */}
                <Grid item xl={3} md={3} sm={3} lg={3} xs={12}>
                    <input type="text" name="" id="" />
                </Grid>
                <Grid item xl={3} md={3} sm={3} lg={3} xs={12}>
                    <select id='type'>
                        <option value="option">Months</option>
                        <option value="option">Days</option>
                        <option value="option">Weeks</option>
                        <option value="option">Years</option>
                    </select>
                </Grid>
                <Grid item xl={3} md={3} sm={3} lg={3} xs={3}>
                    <input type="number" name="" id="" placeholder='3000'/>
                </Grid>
                <Grid item xl={3} md={3} sm={3} lg={3} xs={3}>
                <select id='type'>
                    <option value="option">USD</option>
                    <option value="option">$$</option>
                    <option value="option">FR</option>
                    <option value="option">AUS</option>
                </select>
                </Grid>
                </Grid>
                </div>
            </Grid>
        </div>

            )
        })        
    )
}


const Allmilestones =[];

export const SellContract =({contract_type})=>{
const [milestoneAmt,setmilestone] = React.useState(0)

const handleAddMilseStone =()=>{
    Allmilestones.push('milestone')
    setmilestone(Allmilestones.length)
    console.log(milestoneAmt)
}

const [type,setType]=React.useState(contract_type);
const handleSwitch = ()=>{
    type=='service'?setType('product'):setType('service')
} 
    return(
        <div className='main-contract-container'>
            <div >
                <h1>Sell {type}</h1>
                <Button onClick={handleSwitch}>Switch to {type==='service'?'product':'service'}</Button>
            </div>
            <form action="">
            {type=='service'?<SellService/>:<SellProduct/>}
            <div className='MileStones'>
            <MileStone amount={Allmilestones} mileamount={milestoneAmt}/>
            </div>
            <div style={{width:'fit-content',margin:'0px auto'}}>
                <button type='submit'>submit</button>
            </div>
            </form>
            <div onClick={handleAddMilseStone} className='add-milestone'>
                    <p>Add Milestone</p> <span><ControlPointRoundedIcon/></span>
                    </div>
        </div>
    )
}

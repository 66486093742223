import { CssBaseline, Grid,Chip,InputBase } from '@mui/material'
import React,{useState,useEffect} from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CachedIcon from '@mui/icons-material/Cached';
import BlockIcon from '@mui/icons-material/Block';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SearchIcon from '@mui/icons-material/Search';
import { useMediaQuery } from 'react-responsive'
import './SithView.css'


export const SixthView=()=> {
    const faqs = [
        {
            icon:<FavoriteBorderIcon/>,
            question:'How does it work?',
            solution:'Online escrow is a five-step payment process that protects Buyers and Sellers in a transaction.'
        },
        {
            icon:<CachedIcon/>,
            question:'How long does the process take?',
            solution:'Online escrow is a five-step payment process that protects Buyers and Sellers in a transaction.'
        },
        {
            icon:<BlockIcon/>,
            question:'How should i ship merchandise?     ',
            solution:'Online escrow is a five-step payment process that protects Buyers and Sellers in a transaction.'
        },
        {
            icon:<AddCircleOutlineIcon/>,
            question:'How long does the buyer has to inspect the items? ',
            solution:'Online escrow is a five-step payment process that protects Buyers and Sellers in a transaction.'
        },
        {
            icon:<LocalAtmIcon/>,
            question:'How does billing work?',
            solution:'Online escrow is a five-step payment process that protects Buyers and Sellers in a transaction.'
        },
        {
            icon:<MailOutlineIcon/>,
            question:'How do I change my account email?',
            solution:'Online escrow is a five-step payment process that protects Buyers and Sellers in a transaction.  '
        }
    ]
    const [searchItem,setSearch] = useState([])
    const [searchTerm,setSearchTerm] = useState('')
    useEffect(()=>{
        setSearch(faqs)
    },[])
const handleChange=(e)=>{
    if(e.target.value === ''){
        setSearch(faqs)
    }
    setSearchTerm(e.target.value.toLowerCase())
    setSearch(faqs.filter(faq=>faq.question.toLowerCase().includes(searchTerm)))
}
const isMobile = useMediaQuery({query:`(max-width:600px)`})
  return (
    <div className="page-wrapper">
    <div className='sixthView' >
            <div className='blurry-background blur-13'></div>

        <div className='faq-search-container'>
        <Chip label="FAQs"/>
            <h1 className='smallh1-48 heading centerd'>Frequently asked questions</h1>
            <p className='p-18 '>Have questions? we're here to help</p>
            <div className='searchBox'> {/*Search box and search icon*/}
                <input 
                type='text' 
                className='faq-input'
                placeholder='Search....'
                onInput={handleChange} 
                value={searchTerm}/>
            </div>
        </div>

        <div className='search-answers-container'> {/*Display search results if user searches else display all the items*/}
              <div>
                <Grid container spacing={isMobile?4:1}>
                    {
                        searchItem?.map((faq,index)=>(
                            <Grid item xs={12} lg={4} xl={4} sm={6}
                            key={index}> 
                            <div className='single-faq'>
                            {/*One search result Div*/}
                                <span className='search-solution-icon'>{faq.icon}</span>
                                <p className='question'>{faq.question}</p>
                                <p className='p-16 answer'>{faq.solution}</p>
                                </div>
                            </Grid>    
                        ))
                    }
                </Grid>
              </div>
        </div>
    </div>
    </div>
  )
}


import { Avatar} from '@mui/material'
import { transactions } from '../Overview/StaticData';
import bitcoin from '../../../assets/bitcoin-yellow.svg'
import React from 'react';
import { useMediaQuery } from 'react-responsive'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import './Transactions.css'


const TransactionsHistory =()=>{
const isMobile = useMediaQuery({query:`(max-width:600px)`})
const transactionLength = transactions.length
const [show, setShow] = React.useState(3);
const handleShowMore = ()=>{
setShow(prev=>prev+1)
}

return (

isMobile?( //IF the current device is a mobile device, return this
    <div className='history-container'>
        <h1>Transaction history</h1>
        <div className='the-history-items'>
            {transactions.slice(0,show).map((trans,index)=>{
                return(
                    <div className='history-container-single' key={index}>
                        {/*Picture Div*/}
                        <div className='history-container-imace-holder'> 
                        </div>
                        {/*Info Div*/}
                        <div className='single-content-container'>
                            {/*name n 3dots Div*/}
                            <div className='single-header'>
                                <p>{trans.name}</p>
                                <MoreHorizIcon/>
                            </div>
                            {/*Price n item name Div*/}
                            <div className='single-footer'>
                                <div className='single-price-box'>{trans.amount}</div>
                                <p>{trans.item}</p>
                            </div>
                        </div>
                    </div>
                )
            })}
            <div className='loadMore'>
            <button className='btn' onClick={handleShowMore}>Load more</button>
            </div>
            
        </div>
    </div>
):( //Else if the current device is a desktop ro laptop, Return this instead!
    <div className='history-container-table'>
    <h1>Transaction history</h1>
    <div className='table-wrapper'>
        <table className='the-table'>
            <thead style={{color:'#6F767E',borderBottom:'1px solid #EFEFEF'}} >
                <th >Name</th>
                <th className='themial'>Email</th>
                <th >Amount</th>
                <th >Items</th>
            </thead>
            <tbody>
                {transactions.slice(0,show).map((item,index)=>{
                return(
                <React.Fragment key={index}>
                    <tr>
                        <td className='user'>
                            <Avatar src={bitcoin}/>
                            <div>
                                <p className='name'>{item.name}</p>
                                <p className='atUser'>{item.username}</p>
                            </div>
                        </td>
                        <td className='email'>
                            <p>{item.email}</p>
                        </td>
                        <td className='amount'>
                            <div className='amt-div'>
                            <p>{item.amount}
                            </p>
                            </div>
                        </td>
                        <td className='item'>
                        <p>{item.item}</p>
                        </td>
                    </tr> 
                </React.Fragment>

                )
                })}
            </tbody>
        </table>
        <div style={{display:'flex',justifyContent:'space-around', width:'100%'}}>
            <button className='btn see-moreBtn' onClick={handleShowMore}>See more</button>
        </div>
    </div>
</div>
)
)
}

export default TransactionsHistory;

import './follow.css';
import React from 'react';
import profile from '../../../assets/profile.png'



export default function Follow() {
   
  
     const handleClick = event => {
        event.currentTarget.nextSibling.classList.toggle('active');
      };


  return (
    <>
      <div className="follower-wrapper">
            <div className="hero-follower hero-sect">
                <div className="text-container">
                <h1>Our Followers</h1>
                <p>We're not just an online OnlineP2P service, We're THE online escrow servics</p>
                </div>
            </div>
            <div className="follow-wrap">
                <div className="container">
                    <h4>Friends</h4>
                    <h3>All Followers</h3>
                   <span className='follow-search'> 
                     <i className="fa-solid fa-magnifying-glass"></i>
                     <input type="serach"  />  
                   </span>
                    <div className="all-followers">
                    <div className="profile">
                        <div className="profile-img">
                            <img src={profile} alt="" />
                            <span className="profile-active">
                            </span>
                        </div>
                        <div className="profile-content">
                            <h4 className="profile-name">profile name</h4>
                            <span className="follow-actions" onClick={handleClick}  >...
                          
                            </span>
                            <div className='follow-action-back '>
                             <ul>
                                <li>
                                    <i className="fa-brands fa-facebook-messenger"></i>
                                    <h6>Message User</h6>
                                </li>
                                <li>
                                    <i class="fa-solid fa-user-plus"></i>
                                    <h6>Follow back User<span>see more about user</span> </h6>
                                </li>
                                <li>
                                    <i class="fa-solid fa-user-xmark"></i>
                                    <h6>Block User <span>User won'table to see you again</span></h6>
                                </li>
                             </ul >

                        </div>
                        </div>
                       
                       
                    </div>
                    <div className="profile">
                        <div className="profile-img">
                            <img src={profile} alt="" />
                            <span className="profile-active">
                            </span>
                        </div>
                        <div className="profile-content">
                            <h4 className="profile-name">profile name</h4>
                            <span className="follow-actions" onClick={handleClick} >...
                            </span>
                            <div className='follow-action-back '>
                             <ul>
                                <li>
                                    <i className="fa-brands fa-facebook-messenger"></i>
                                    <h6>Message User</h6>
                                </li>
                                <li>
                                    <i class="fa-solid fa-user-plus"></i>
                                    <h6>Follow back User<span>see more about user</span> </h6>
                                </li>
                                <li>
                                    <i class="fa-solid fa-user-xmark"></i>
                                    <h6>Block User <span>User won'table to see you again</span></h6>
                                </li>
                             </ul >

                        </div>
                        </div>

                    </div>
                    <div className="profile">
                        <div className="profile-img">
                            <img src={profile} alt="" />
                            <span className="profile-active">
                            </span>
                        </div>
                        <div className="profile-content">
                            <h4 className="profile-name">profile name</h4>
                            <span className="follow-actions" onClick={handleClick} >...</span>

                            <div className='follow-action-back '>
                                    <ul>
                                        <li>
                                            <i className="fa-brands fa-facebook-messenger"></i>
                                            <h6>Message User</h6>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-user-plus"></i>
                                            <h6>Follow back User<span>see more about user</span> </h6>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-user-xmark"></i>
                                            <h6>Block User <span>User won'table to see you again</span></h6>
                                        </li>
                                    </ul >
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </div>
    </>
  )
  
}

import React from 'react'
import cate1 from '../../../../assets/bushmarket.svg'
import cate2 from '../../../../assets/foodmarket.svg'
import cate3 from '../../../../assets/hardwaremarket.svg'
import cate4 from '../../../../assets/techmarket.svg'
import searchIcon from '../../../../assets/SearchIcon.svg'
import { useNavigate } from 'react-router-dom'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { useMediaQuery } from 'react-responsive'
import './SearchStyle.css'

export const Search =({alltheItems})=>{
  const isMobile = useMediaQuery({query:`(max-width:600px)`})
  const handleClick=()=>{
    console.log('OKEEEEEEEE')
  }
  const navigate = useNavigate();
  const handleSelect = (e,value)=>{
    navigate(`/MarketPlace/${value.id}`)
  }
    return(
        <div className="marketpalceSearch-wrap">
          <div className='Market-place-search'>
            <p>Escrow Marketplace</p>
            <div className='Search-flex'>
              <select name="sort" id="sort">
                <option disabled value="Sort by">sort By..</option>
                <option value="price">Price</option>
                <option value="date">Date</option>
                <option value="reviews">Reviews</option>
              </select>
              <div className='Search-flex2'>
              <input type="text" placeholder='search' />
              <img src={searchIcon} className='searchIco'/>
              </div>
              
            </div>
          </div>
          <div className='markets'>
            <p>
              <img src={cate1}/> textDeatils
            </p>
            <p>
            <img src={cate2}/> textDeatils
            </p>
            <p>
            <img src={cate3}/> textDeatils
            </p>
            <p>
            <img src={cate4}/> textDeatils
            </p>
          </div>
        </div>
    )
}

import {Chip, Avatar,Typography, Grid} from '@mui/material'
import letfArrow from '../../../assets/leftArrow.svg'
import rightArrow from '../../../assets/rightArrow.svg'
import bitcoin from '../../../assets/bitcoin-yellow.svg'
import binance from '../../../assets/binance-yellow.svg'
import etherum from '../../../assets/etherum-blue.svg'
import TeTher from '../../../assets/ThtherUSDT-green.svg'
import quotes from '../../../assets/quote.svg'
import Payeer from '../../../assets/Payeer.svg'
import BlockIo from '../../../assets/blockIO.svg'
import More from '../../../assets/More.svg'
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive'
import React from 'react'
import './FIthView.css'


// //React slick Slider arrow to move left
// const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
//   <img src={letfArrow} alt="prevArrow" {...props} style={{color:'red',height:'5vh',}}/>
// );

// //React slick Slider arrow to move Right
// const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
//   <img src={rightArrow} alt="nextArrow" {...props} style={{color:'red',height:'5vh'}}/>
// );
//Static data for testimonials!!
const testimonials =[
  {
    name:"Jane Cooper",
    status:"CEO, ABC Corporation",
    title:"Incredible Experience",
    comment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus natus illo itaque cumque id suscipit aperiam. Repudiandae quas ducimus corrupti nisi tempore nemo aspernatur necessitatibus ut. Fugit commodi expedita molestiae debitis quaerat ad porro amet, fugiat eaque quibusdam odio doloribus."
  },
  {
    name:"Jane Coope",
    status:"CEO, ABC Corporation",
    title:"Dependable, Responsive Partner",
    comment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus natus illo itaque cumque id suscipit aperiam. Repudiandae quas ducimus corrupti nisi tempore nemo aspernatur necessitatibus ut. Fugit commodi expedita molestiae debitis quaerat ad porro amet, fugiat eaque quibusdam odio doloribus."
  },
  {
    name:"Ojong Clinton",
    status:"React Developer",
    title:"Change This Info",
    comment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus natus illo itaque cumque id suscipit aperiam. Repudiandae quas ducimus corrupti nisi tempore nemo aspernatur necessitatibus ut. Fugit commodi expedita molestiae debitis quaerat ad porro amet, fugiat eaque quibusdam odio doloribus."
  },
  {
    name:"Ojong Clinton",
    status:"React Developer",
    title:"Change This Info",
    comment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus natus illo itaque cumque id suscipit aperiam. Repudiandae quas ducimus corrupti nisi tempore nemo aspernatur necessitatibus ut. Fugit commodi expedita molestiae debitis quaerat ad porro amet, fugiat eaque quibusdam odio doloribus."
  }
]

export const FithView =()=>{
  const isMobile = useMediaQuery({query:`(max-width:600px)`})
const isMediumScreen = useMediaQuery({query:`(max-width:750px)`})

const checkScreenForSlider=()=>{ //Function that cheks the screen size to determine how many slders to show
  if(isMobile){
    return 1;
  }
  else if(isMediumScreen){
    return 1
  }
  else {
    return 2
  }
}

  const [currentSlide,setCurrentSlide] = React.useState(0)
  
//This are the options for the react slick component
var settings = {
  beforeChange:function(prev,next){
    setCurrentSlide(next)
  },
  customPaging: function(pagi, i) {
    const style={
      height:'10px',
      width:'10px',
      borderRadius:'100px',
      border:'2px solid #82839e'
    }
    const activeStyle={
      height:'10px',
      width:'10px',
      borderRadius:'100px',
      backgroundColor:'white'
    }
    return ( //Dots style for react slider
      <div className='slick-dot' style={pagi===currentSlide?activeStyle:style}> </div>
    );
  },
  className:isMobile?'Mcenter':'center',
  dots:true,
  infinite:true,
  speed:1000,
  slidesToShow:checkScreenForSlider(),
  slidesToScroll:1,
  arrows:isMobile?false:true,
  autoplay:false,
  autoplayspeed:400

}

  return(
    <div className="page-wrapper">
    <div className="FithView" >
      <Grid container spacing={1} classes={{root:"gridStyle"}}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className='titleBox'>
      <Chip label="Payment"/>
      <h1 className="smallh1-48">We accept these different crypto</h1>
      <p className='p-18'>Safely buy and sell products and services from $100 to $10 million or more</p>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className='halfCircle'>
          <div className='iconsDiv'>
            <img src={bitcoin} alt="bitcoinIMG"/>
            <img src={binance} alt="bitcoinIMG"/>
            <img src={etherum} alt="bitcoinIMG"/>
            <img src={TeTher} alt="bitcoinIMG"/>
          </div>
          <div className='iconsDiv2'>
            <img src={Payeer} alt="bitcoinIMG" />
            <img src={BlockIo} alt="bitcoinIMG"/>
            <img src={More} alt="bitcoinIMG"/>
          </div>
          </div>
          </Grid>
      </Grid>
      </div>

      <div className="page-wrapper">
      <div className='testimonials-Box'>
        <div  className='testimonials-Box-title'>
            <h1 className='smallh1-48 header'>Testimonial</h1>
        </div>
        <Slider {...settings} className='slider-component'>
            {testimonials.map((testimony,key)=>{
            return(
                <div key={key}>
                    <div className='single-testimony'> {/*Testimony div*/}
                        <h1 className=' testimony-head'>{testimony.title}</h1>
                        <p className='p-14 testimony-body'>{testimony.comment}</p>
                    </div>
                    <div className='testimony-user-info'> {/*User who posted testimony*/}
                        <Avatar src={bitcoin} alt={testimony.name} classes={{root:'comment-icon'}}/>
                        <div>
                            <p className='p-18 comment-name'>{testimony.name}</p>
                            <p className='p-14 comment-status'>{testimony.status}</p>
                        </div>
                    </div>
                </div>
            )
            })}
        </Slider>
    </div>
</div>
</div>
  )
}

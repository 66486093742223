import React from 'react'
import { Routes,Route } from 'react-router-dom';
import Privacy from './Privacy/Privacy';
import Contact from './Contact/Contact';
import AboutUs from './About/AboutUs';
import MessageCen from './MessageCenter/MessageCen';
import Testimonial from './Testimonial/Testimonial';
import Blogs from './Blog/Blogs';

const Info =()=>{
    return(
        <Routes>
            <Route path='/AboutUs' element={<AboutUs/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/privacy' element={<Privacy/>}/>
            <Route path='/MessageCenter' element={<MessageCen/>}/>
            <Route path='/MessageCenter' element={<MessageCen/>}/>
            <Route path='/MessageCenter' element={<MessageCen/>}/>
            <Route path='/Testimonial' element={<Testimonial/>}/>
            <Route path='/Blogs' element={<Blogs/>}/>
        </Routes>
    )
}

export default Info;

import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid,Chip,Rating,Button } from '@mui/material'
import { comments, items } from '../MarketPlace'
import imagePlaceHolder from '../../../assets/imagePlaceholder.jpg'
import imagePlaceHolder1 from '../../../assets/imagePlaceholder1.jpg'
import imagePlaceHolder2 from '../../../assets/imagePlaceholder2.jpg'
import SwapHorizontalCircleRoundedIcon from '@mui/icons-material/SwapHorizontalCircleRounded';
import { useMediaQuery } from 'react-responsive';
import { Modal, Box, Fade, Typography, Backdrop } from '@mui/material';
import { useForm } from "react-hook-form";
import closecircle from '../../../assets/closecircle.svg';
// import '../../ContractForm/contract.css';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};




function ItemDetails() {
const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
const pics = [imagePlaceHolder,imagePlaceHolder1,imagePlaceHolder2]

 /*form*/
 const {
  register: register5,
  formState: { errors: errors5 },
  handleSubmit: handleSubmit5,
  reset: reset,

} = useForm({
  mode: "onBlur",
});

const contractForm = (data) => {
  console.log(JSON.stringify(data.tel));
  alert(`Login Successful!`);
  reset();
};


const isMobile = useMediaQuery({query:`(max-width:600px)`})
const [showComments,setShowComments] = React.useState(2);
const showMoreComments = ()=>{
  setShowComments(comments.length)
}
const {itemId} = useParams()
const theItem = items.find(item=>item.id == itemId)

const [currentImage,setCurrentImage] = React.useState(imagePlaceHolder)
const [anId,setId] = React.useState(0)
const hadleImageChange =(e)=>{
  setCurrentImage(pics[e])
  setId(e)
}

  return (

    <>
  
    <div className='single-item-detailed'>
      <Grid container gap={0}>
        <Grid item xl={7} lg={7} md={7} xs={12}>
          <div className='detailed-pics'>{/*The currently selected image*/}
            <img src={currentImage} alt="itemImage"/>
            <div className='detailed-other-pics'>{/*Other Item images */}
            {pics.map((pic,index)=>{
              return(
                <div className={anId===index?'detailed-other-pics-active':'detailed-other-pics-inactive'} onClick={()=>hadleImageChange(index)} key={index}>
                <img 
                src={pic} 
                alt="otherImages" />
              </div>
              )
            })}
            </div>
          </div>
        </Grid>
        <Grid item xl={5} lg={5} md={5} xs={12}>
          <div className='detailed-text'>
              <div className='detailed-head'> {/*name and tag container*/}
              <div>
                  <h1>{theItem.name}</h1>
                  <div>
                    <Rating
                     value={3} 
                     readOnly 
                     size={isMobile?"small":"medium"}
                     />
                    <p>440+ Reviewers</p>
                  </div>
              </div>
              <Chip label={theItem.category} className='detailed-chip'/>
              </div>
              <div> {/*Item Description*/}
                <p className='desc-text'>
                  {theItem.description}
                </p>
              </div>
              <div className='detailed-foot'> {/*Item type details*/}
                <div>
                  <p>Type</p>
                  <p>Category</p>
                </div>
                <div className='bData'>
                  <p>{theItem.type}</p>
                  <p>{theItem.category}</p>
                </div>
                <div>
                  <p>Details 1</p>
                  <p>Details 2</p>
                </div>
                <div className='bData'>
                  <p>Details 3</p>
                  <p>Details 4</p>
                </div>
              </div>
              <div className='detailed-foot'> {/*Price and Contract*/}
                <div>
                  <h2>{theItem.amount}</h2>
                </div>
                <div className='detailed-buy'>
                  <div> 
                  <SwapHorizontalCircleRoundedIcon 
                  style={{color:'#666666'}}/>
                   </div>
                  <button className='btn' onClick={handleOpen}>
                    Contract
                  </button>
                </div>
              </div>
          </div>
        </Grid>
      </Grid> 

      <div className="comment-container">
      <p>Reviews <span>{comments.length}</span></p>
            <div>     
              <div>
                {comments.slice(0,showComments).map((comment,index)=>{
                  return(
                    <div className="single-comment" key={index}>
                      <div> {/*Comment Header*/}
                        <div> {/*Comment header Left Side*/}
                          <p className='com-name'>{comment.name}</p>
                          <p className='com-title'>{comment.title}</p>
                        </div>
                        <div>{/*Comment header Right side*/}
                          <p className='com-date'>{comment.date}</p>
                          <Rating readOnly value={3.5} precision={0.5} size={isMobile?"small":"medium"} />
                        </div>
                      </div>
                      <p className='com-body'>
                      {comment.comment}
                      </p>
                    </div>
                  )
                })

                }
              </div>
              <Button  disabled={showComments==comments.length} onClick={showMoreComments} className='com-showMore'>Show More</Button>
            </div>
            </div>
    </div>

    


    <div className='contract-form'>
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        className="contractformWrap" >
        <Fade in={open}>
            <Box sx={style}>    
                <h3>Buy contract</h3>
                <form key={5} onSubmit={handleSubmit5(contractForm)} className="contractForm">
                    <div className='col col-50' >
                        <label>User Name</label>
                        <input type="email" {...register5("email", { required: true, pattern: /^\S+@\S+$/i })} placeholder="Email" />
                        {errors5.email && <span className='error'>* write the email with correct pattern</span>}
                    </div>
                    <div className='col col-50' >
                        <label>User Id</label>
                        <input type="password" {...register5("password", { required: true })} placeholder="Password" />
                        {errors5.password && <span className='error'>* Enter your Password</span>}
                        </div>
                    <div className='col col-100' >
                        <label>Product details</label>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</p>
                    </div>
                    <div className='col col-100'>
                      <label>Location</label>
                      <input type="text" placeholder="Location" {...register5("location", {required: true})} />
                      {errors5.location && <span className='error'>* Enter your Location</span>}
                    </div>
                    <div className='col col-100'>
                        <label>Payment method</label>
                        <select {...register5("paymentmethod" , { required: true })}>
                            <option value=""> Select</option>
                            <option value="1">Bank Account</option>
                            <option value="2">Visa Card</option>
                            <option value="3">Stripe</option>
                        </select>
                        {errors5.paymentmethod && <span className='error'>* Select Payment Method</span>}
                    </div>
                    <div className='col-50 col'>
                        <label>Day</label>
                        <select {...register5("day" , { required: true })}>
                            <option value="">Select Day</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                        </select>
                        {errors5.day && <span className='error'>* Select Day</span>}
                    </div>
                    <div className='col-50 col'>
                    <label> Month</label>
                    <select {...register5("month" , {required: true})}>
                        <option value="">Select Month</option>
                        <option value="January">January</option>
                        <option value="Feburary">Feburary</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                    </select>
                       {errors5.month && <span className='error'>* Select Month</span>}
                    </div>
                    <input type="submit" value="View contract" className='mar-cen' />
                </form>
                <span onClick={handleClose} className="closebtn"><img src={closecircle}/></span>
            </Box>
        </Fade>
    </Modal>
</div>

</>
  );
}

export default ItemDetails

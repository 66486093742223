import {Chip, CssBaseline} from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ProtoTypes from "prop-types"
import { useMediaQuery } from 'react-responsive'
import './FourthView.css';
import React from 'react';

//The Iframe for the youtube video
const YoutubeEmbed = ({ embedId,height,width}) => (
  <div className='video-container'>
    <iframe className='actualVid'
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

YoutubeEmbed.prototypes = {
  embedId: ProtoTypes.string.isRequired
}

export const FourthView =()=>{
  const isMobile = useMediaQuery({query:`(max-width:600px)`})
  return(
    <div className="page-wrapper">
<div className='FourthView' >
    <CssBaseline/>
    <div>
    <div className='blurry-background blur-12'></div>
        <div className='whoWeAre-container'>
        <div className='chip-box'>
            <Chip label="Who we are"/>
            <h1 className='smallh1-48 heading leftAlign'>About Us</h1>
            <p className='p-18 details-text centered-text'>Welcome to People-Powered Technology</p>
        </div>

        <div className='details-box'>
            {!isMobile && <p className='p-18 details-text'>OnlineP2P is entirely peer-to-peer, which means our users trade with real people the way bitcoin was intended to be used We're not just an online escrow service, We're THE BEST online escrow service, In just less than five years, weve become one of the leading peer-to-peer bitcoin marketplaces used by millions around the world. And were just getting started. </p>} 
            {!isMobile && <p className='p-18 details-text learnMore'>Learn More<KeyboardArrowRightIcon classes={{root:'arrowleft'}}/></p>}

        </div>

        </div>
        <div>

            <YoutubeEmbed embedId="UUgSzLzxJyo"/> {/*Change the embedId hereASAP!!!*/}
        </div>

    </div>
    
</div>
</div>
  )
}

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import settingicon from '../../../assets/settings.svg'
import { useNavigate } from 'react-router-dom';
import './Category.css'


const Category = ({setCategory,categories,choosed}) => {
const navigate = useNavigate()
const handleCate =(category)=>{
    navigate(`/Marketplace/categories/${category}`)
    setCategory(category)
}
  return (
    <div className='categories-container'>
        <div>
        {/* <p css={styles.nameStyle} onClick={()=>{navigate('/Marketplace/categories')}}>All Categories</p> */}
            {categories.map((category,index)=>{
                return(
                    <div key={index}
                    className='a-category' 
                    onClick={()=>handleCate(category)} 
                    style={choosed===category?{backgroundColor:'#5A48FB'}:{}}>
                        <img src={settingicon} />
                        <p style={choosed==category?{color:'white'}:{}}>{category}</p>
                    </div>
                )
            })}
        </div>
        <div className='btn-div'>
            <button className='btn' 
            onClick={()=>navigate('/Dashboard/Create-contract')}
            >
            <DashboardCustomizeIcon className='cusIco'/> Create New escrow
            </button>
        {/* <Button variant='contained' onClick={()=>navigate('/Dashboard/Create-contract')}><DashboardCustomizeIcon style={{marginRight:'1vh'}}/> Create New escrow</Button> */}
        </div>
    </div>
  )
}

export default Category

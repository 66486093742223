import {Grid,Chip} from '@mui/material'
import boxstep from '../../../assets/boxStepper.svg'
import { useMediaQuery } from 'react-responsive'
import React from 'react' 
import './SecondView.css'

export const SecondView=()=>{
//Query to check if current device being used is a mobile or a pc sho that the different styles should apply!...returns either true or false
const isMobile = useMediaQuery({query:`(max-width:600px)`})

const process = [
  {title:"1. Sign Up",body:"We’ve helped over 2,500 job seekers to get into the most popular tech teams."},
  {title:"2. TopUp Account",body:"We’ve helped over 2,500 job seekers to get into the most popular tech teams."},
  {title:"3. Make Escrow",body:"We’ve helped over 2,500 job seekers to get into the most popular tech teams."},
  {title:"4. Complete",body:"We’ve helped over 2,500 job seekers to get into the most popular tech teams."},
  {title:"5. Repeat",body:"We’ve helped over 2,500 job seekers to get into the most popular tech teams."},
]
  return(
    <div className="page-wrapper">
    <div className='secondView'>
        <div >
        {isMobile && 
        <div className='mobile-elevated-box'>
            <p>
              Learn about our story and our mission statement
            </p>
        </div>}
        <div className='center-box'>
          <Chip label="Simple Step"/>
        </div>
        </div>
      <Grid container spacing={2} >
        <Grid item xl={5} xs={12} md={5} className="home-solution-cont">
          <h1 className='smallh1-48 centered'>How our onlinep2p solution works</h1>
          <p className='p-18 simple'>A few simple, easy steps to make it easier for you</p>
          <div className='blurry-background blur-7'></div>
          <div className='blurry-background blur-8'></div>

        </Grid>
        <Grid item xs={12} sm={12} xl={7}  md={7}>
          <Grid container spacing={1} className="home-solution-icons">
            {process.map((process,index)=>{
              return(
                <Grid item xs={12} sm={12} lg={6} xl={6} md={12}>
                  <div className='stepDiv-container' key={index}>
                  <img src={boxstep} className='stepDiv-img'/>
                  <div>
                    <h3 className='h3-24 step-Title'>{process.title}</h3>
                    <p className='p-16 stepText'>{process.body}</p>
                  </div>
                  </div>
                </Grid>
              )
            })
            }
          </Grid>
        </Grid>
      </Grid>

      
    </div>
    </div>
  )
}
